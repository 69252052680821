import styled from "@emotion/styled";
import { Cancel, Check, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/services";
import Sidebar from "../components/SideBar";
import { Colors } from "../styles/colors";
import { roles } from "../utils/utils";
import { getUserId, updateRole } from "../api/auth";

const Root = styled.div`
  display: flex;
`;

const RootBox = styled.div`
  background-color: ${Colors.background};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100dvh;
  /* border: 5px solid red; */
`;

const Section = styled(Card)`
  margin: 33px;
  width: 100%;
  background-color: white;
  /* border: 1px solid black; */
  border-radius: 8px;
`;

const Header = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 2px solid whitesmoke;
`;

const Body = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function EditUserPage() {
  const [id, setId] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [role, setRole] = useState(roles[0]);
  const [sector, setSector] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const locale = useLocation();

  const isNewUser = locale.state == null ? true : locale.state.id === undefined;

  const handleChange = (event) => {
    setRole(roles.find((it) => it.value === event.target.value));
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isNewUser) {
      api
        .get("/api/v1/user/" + locale.state.id)
        .then((response) => {
          setId(response.data.id);
          setFirstName(response.data.first_name);
          setLastName(response.data.last_name);
          setEmail(response.data.email);
          setUsername(response.data.username);
          setRole(
            roles.find((it) => it.value === response.data.user_permissions[0])
          );
        })
        .catch((err) => console.error(err));
    }
  }, [isNewUser]);

  useEffect(() => {
    var errText = "";
    if (password !== password2) errText += "As senhas tem que ser iguais.;";
    if (password.length > 0 && password.length <= 8)
      errText += "As senhas tem que ter mais de 8 dígitos.;";
    setError(errText);
  }, [password, password2]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append("username", username);
    data.append("email", email);
    if (password !== "") data.append("password", password);
    if (password !== "") data.append("password2", password2);
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("user_permissions", role.value);

    console.log(getUserId(), id, role.value);
    if (id !== null && id == getUserId()) {
      updateRole(role.value);
    }

    if (isNewUser) {
      api
        .post("/api/v1/users", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          toast.success("Usuário criado com sucesso!");
          navigate("/users");
        })
        .catch((error) => {
          const res = error.response.data;
          let errorText = "";
          for (var key in res) {
            errorText += "Campo: " + key + "\n\t-";
            errorText += res[key].join("\n\t-");
            errorText += "\n";
          }
          toast.error("Erro ao fazer o cadastro!\n" + errorText, {
            duration: 5000,
          });
        });
    } else {
      api
        .patch("/api/v1/user/" + locale.state.id, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          toast.success("Usuário atualizado com sucesso!");
          if (password !== "") {
            const dataPass = new FormData();
            dataPass.append("password", password);
            dataPass.append("password2", password2);
            api
              .patch("/api/v1/change_password/" + locale.state.id, dataPass, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then(() => navigate("/users"));
          } else {
            navigate("/users");
          }
        })
        .catch((error) => {
          const res = error.response.data;
          let errorText = "";
          for (var key in res) {
            errorText += "Campo: " + key + "\n\t-";
            errorText += res[key].join("\n\t-");
            errorText += "\n";
          }
          toast.error("Erro ao fazer o cadastro!\n" + errorText, {
            duration: 5000,
          });
        });
    }
  };

  return (
    <Root>
      <Sidebar />
      <RootBox>
        <Section>
          <Header>
            <Typography variant="h4">
              {isNewUser ? "Cadastras usuário" : "Editar Usuário"}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<BiLeftArrowAlt />}
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </Header>
          <Body>
            <Box
              sx={{ all: "unset", width: "500px" }}
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Stack gap={2}>
                <TextField
                  label="Primeiro Nome"
                  variant="outlined"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  label="Último Nome"
                  variant="outlined"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  label="Usuário"
                  variant="outlined"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role.value}
                    label="Perfil"
                    defaultValue={1}
                    onChange={handleChange}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <TextField
                  label="Setor/Função"
                  variant="outlined"
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                /> */}

                <FormControl error={error !== ""}>
                  <TextField
                    variant="outlined"
                    label="Senha"
                    error={error !== ""}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    variant="outlined"
                    label="Confirmar Senha"
                    error={error !== ""}
                    value={password2}
                    sx={{ mt: 2 }}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword2(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error !== "" && (
                    <FormHelperText id="component-error-text">
                      <ul>
                        {error
                          .split(";")
                          .filter((it) => it !== "")
                          .map((it) => (
                            <li>{it}</li>
                          ))}
                      </ul>
                    </FormHelperText>
                  )}
                </FormControl>

                <Box>
                  <em>*A senha só será alterada se o campo for preenchido!</em>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                    width: "500px",
                  }}
                >
                  <Stack direction={"row"} gap={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<Cancel />}
                      onClick={() => navigate(-1)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      type="submit"
                      startIcon={<Check />}
                      // onClick={() => navigate(-1)}
                    >
                      Confirmar
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Body>
        </Section>
      </RootBox>
    </Root>
  );
}
