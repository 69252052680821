import api from "./services";
import toast from "react-hot-toast";

/**
 * @brief Documento onde são definidos parâmetros para login e logout de usuário.
 * Aqui são configuradas as funções para setar o token de autenticação JWT.
 */

const TOKEN_KEY = "token_key";
const REFRESH_TOKEN = "refresh_token";
const ROLE = "role";
const USER_ID = "userId";
/**
 * @function isAuthenticated
 *
 * @brief Verifica se existe um token JWT para o usuário que está utilizando o sistema. O token pode ou não ser válido.
 *
 * @return Boolean
 *
 */
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

/**
 * @function getToken
 *
 * @brief Busca o token JWT armazenado no localStorage.
 * @return String com token JWT do usuário Logado.
 */
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getUserId = () => localStorage.getItem(USER_ID);

/**
 * @function getRefresh
 *
 * @brief Busca o refresh token do localStorage
 *
 * @return String contendo o Refresh Token
 */
export const getRefresh = () => localStorage.getItem(REFRESH_TOKEN);
export const getRole = () => localStorage.getItem(ROLE);
export const updateRole = (value) => localStorage.setItem(ROLE, value);

/**
 * @function login
 *
 * @brief Faz login de um usuário, caso o mesmo tenha sido encontrado no banco de dados. Armazena o token JWT e o o Refresh Token
 */
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

/**
 * @function logout
 *
 * @brief Remove ambos os tokens: de acesso e de refresh de dentro do armazenamento local.
 */
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ROLE);
};

export const handleLogin = async (username, password) => {
  var data = new FormData();

  data.append("username", username);
  data.append("password", password);
  var result = await api.post("/token/", data).catch((error) => {
    console.log(error);
    if (error.response.status == 401) {
      toast.error("Usuário ou senha inválidos!");
    } else {
      toast.error("Erro ao se conectar com o servidor!");
    }
  });

  if (!result) {
    return false;
  }
  login(result.data.access);
  const d = new Date();
  d.setTime(d.getTime() + 1000 * 60 * 60 * 24);
  let expires = "expires=" + d.toUTCString();
  document.cookie = `username=${username};${expires};path=/`;
  await api.get("/api/v1/users").then(async (response) => {
    const userId = response.data.results.find(
      (user) => user.username === username
    ).id;
    localStorage.setItem(USER_ID, userId);
    await api.get(`/api/v1/user/${userId}`).then((response) => {
      const role = response.data.user_permissions[0];
      if (role) {
        localStorage.setItem(ROLE, role);
      }
    });
  });
  return true;
};
