import { Upload } from "@mui/icons-material";
import { Button, Container, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import api from "../api/services";
import PageBase from "../components/PageBase";

export default function VideosPage() {
  const [videoSrc, setVideoSrc] = useState("");
  const [videoId, setVideoId] = useState("");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    api
      .get("/api/v1/video/0")
      .then((res) => {
        setVideoSrc(res.data.path);
        setVideoId(res.data.path.split("/")[5]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "" && e.target.value.split("/").length < 6) {
      console.log("aaaa")
      setVideoId(e.target.value.split("/")[5]);
    }
    setVideoSrc(e.target.value);
  };

  const handleSave = () => {
    api
      .patch("/api/v1/video/0", { path: videoSrc })
      .then((res) => {
        toast.success("Link do vídeo atualizada com sucesso!");
      })
      .catch((err) => console.log(err));
  };
  return (
    <PageBase title="Vídeo Descanso" loading={loading}>
      <Container sx={{ width: "450px", padding: "10px" }}>
        <Stack spacing={2}>
          <iframe
            title="video"
            width="100%"
            height="600"
            src={"https://drive.google.com/file/d/" + videoId + "/preview"}
          ></iframe>
          <TextField
            id="video-input"
            label="URL do Vídeo"
            variant="outlined"
            value={videoSrc}
            onChange={handleChange}
          />
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Upload />}
            onClick={handleSave}
          >
            ATUALIZAR VÍDEO
          </Button>
        </Stack>
      </Container>
    </PageBase>
  );
}
