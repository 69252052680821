import { Add, Delete, Edit } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  ptBR,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import api from "../api/services";
import PageBase from "../components/PageBase";
import QuestionDialog from "../components/QuestionDialog";
import { roles } from "../utils/utils";

function getFullName(params) {
  return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
}

function getFunction(params) {
  if (params.row.user_permissions.length === 0) return "";
  return (
    roles.find((it) => it.value === params.row.user_permissions[0]).label || ""
  );
}

export default function UserScreen() {
  const [rows, setRows] = useState();
  const navigate = useNavigate();

  const toEditPage = (id) => {
    navigate("edit/" + id, { state: { id: id } });
  };

  useEffect(() => {
    api.get("/api/v1/users").then((response) => {
      setRows(response.data.results);
    });
  }, []);

  const deleteUser = (id) => {
    api
      .delete("/api/v1/user/" + id)
      .then((res) => {
        toast.success("Usuário excluído com sucesso!");
        api.get("/api/v1/users").then((response) => {
          setRows(response.data.results);
        });
      })
      .catch((err) => console.error(err));
  };

  const handleEditClick = (id) => () => {
    toEditPage(id);
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    deleteUser(id);
  };

  const handleAddNew = () => {
    navigate("register");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "fullName",
      headerName: "Nome Completo",
      width: 350,
      valueGetter: getFullName,
      disableColumnMenu: true,
    },
    {
      field: "username",
      headerName: "Usuário",
      width: 220,
      // editable: true,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 320,
      // editable: false,
      disableColumnMenu: true,
    },
    {
      field: "function",
      headerName: "Função",
      width: 200,
      valueGetter: getFunction,
      disableColumnMenu: true,
    },
    {
      headerName: "Ações",
      field: "actions",
      type: "actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Editar">
                <Edit />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <QuestionDialog
                title="Excluir o usuário"
                description="Tem certeza que deseja excluir o usuário?"
                onConfirm={handleDeleteClick(id)}
              >
                <Tooltip title="Excluir Item">
                  <Delete />
                </Tooltip>
              </QuestionDialog>
            }
            label="Delete"
            color="inherit"
          />,
        ];
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ m: 1 }}>
        <Button
          variant="outlined"
          color="success"
          startIcon={<Add />}
          onClick={handleAddNew}
          sx={{ marginRight: "10px" }}
        >
          Adicionar Novo
        </Button>
        <GridToolbarFilterButton variant={"contained"} />
      </GridToolbarContainer>
    );
  }

  return (
    <PageBase
      title="Usuários"
      loading={rows === undefined || rows.length === 0}
    >
      <DataGrid
        rows={rows}
        autoHeight
        columns={columns}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        // pageSize={10}
        // rowsPerPageOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          "& .MuiTablePagination-toolbar p": {
            marginBottom: "0",
          },
        }}
      />
    </PageBase>
  );
}
