import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useGlobalState } from "../hooks/useGlobalState";
import FormProgress from "./FormProgress";
import Sidebar from "./SideBar";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: "100%",
    height: "100vh",
    // padding: theme.spacing(3),
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginLeft: -230,
    ...(open && {
      // transition: theme.transitions.create('margin', {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
      marginLeft: 0,
    }),
  })
);

const Root = styled.div`
  display: flex;
  flex-direction: row;
`;

const Section = styled(Card)`
  margin: 33px;
  /* width: 100%; */
  background-color: white;
  border-radius: 8px;
`;

const Header = styled.div`
  background-color: white;
  padding: 20px;
  border-bottom: 2px solid whitesmoke;
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div``;

export default function PageBase(props) {
  const navigate = useNavigate();
  const [open, changeOpen] = useGlobalState("drawerOpen");

  return (
    <Root>
      <Sidebar />
      <Main open={open}>
        <Section>
          <Header>
            <Typography variant="h4">{props.title}</Typography>
            {props.backButton !== undefined && props.backButton && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<BiLeftArrowAlt />}
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            )}
          </Header>
          <Body>
            <Box sx={{ height: "85vh", width: "100%", overflowY: "auto" }}>
              {props.loading !== undefined && props.loading === true ? (
                <FormProgress />
              ) : (
                props.children
              )}
            </Box>
          </Body>
        </Section>
      </Main>
    </Root>
  );
}
