import styled from "@emotion/styled";
import { Cancel, Check } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/services";
import PageBase from "../components/PageBase";

const BodyCard = styled(Paper)`
  padding: 15px;
`;
const HeaderCard = styled.div`
  background-color: white;
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 2px solid whitesmoke;
`;

export default function ShopEditPage() {
  const [loja, setLoja] = useState({});
  const [grupos, setGrupos] = useState([]);
  const navigate = useNavigate();
  const locale = useLocation();

  useEffect(() => {
    api
      .get("/api/v1/loja/" + locale.state.id)
      .then((res) => {
        setLoja(res.data);
      })
      .catch((err) => console.error(err));
    api.get("/api/v1/grupos").then((res) => {
      setGrupos(res.data.results);
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("nome_da_loja", loja.nome_da_loja);
    data.append("nome_responsavel", loja.nome_responsavel);
    data.append("endereco", loja.endereco);
    data.append("cidade", loja.cidade);
    data.append("estado", loja.estado);
    data.append("cep", loja.cep);
    data.append("cv_revenda", loja.cv_revenda);
    data.append("cv_pbshop", loja.cv_pbshop);
    data.append("cv_engenharia", loja.cv_engenharia);
    data.append("cv_exportacao", loja.cv_exportacao);
    data.append("tipo", loja.tipo);
    data.append("status", loja.status);
    data.append("telefone_loja", loja.telefone_loja);
    data.append("consultor_nomes", loja.consultor_nomes);
    data.append("consultor_telefone", loja.consultor_telefone);
    data.append("consultor_email", loja.consultor_email);
    data.append("id_grupo_loja", loja.id_grupo_loja);

    api
      .patch("/api/v1/loja/" + locale.state.id, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        toast.success("Dados da loja alterados com sucesso!");
        navigate("/shops");
      })
      .catch((err) => {});
  };
  const handleEditCep = (event) => {
    if (event.target !== undefined && event.target.value.length === 8) {
      axios
        .get("https://viacep.com.br/ws/" + event.target.value + "/json/")
        .then((res) => {
          setLoja({
            ...loja,
            cep: res.data.cep,
            cidade: res.data.localidade,
            estado: res.data.uf,
            endereco: res.data.logradouro,
          });
        });
    } else {
      setLoja({ ...loja, cep: event.target.value });
    }
  };

  const getValueCanal = () => {
    if (loja.cv_exportacao === "Y") return "EXP";
    else if (loja.cv_pbshop === "Y") return "PBS";
    else if (loja.cv_engenharia === "Y") return "ENG";
    else return "REV";
  };

  return (
    <PageBase title="Editar loja" backButton>
      {grupos.length > 0 && loja !== undefined && loja !== null && (
        <Box
          component="form"
          autoComplete="off"
          p={1}
          onSubmit={handleSubmit}
          sx={{ flexGrow: 1 }}
        >
          <Grid container spacing={2}>
            <Grid xs={6}>
              <BodyCard>
                <HeaderCard>Informações Básicas</HeaderCard>
                <Stack gap={2}>
                  <TextField
                    label="Nome da Loja"
                    variant="outlined"
                    required
                    value={loja?.nome_da_loja || ""}
                    onChange={(e) =>
                      setLoja({ ...loja, nome_da_loja: e.target.value })
                    }
                  />
                  <TextField
                    label="Nome do Responsável"
                    variant="outlined"
                    value={loja?.nome_responsavel || ""}
                    onChange={(e) =>
                      setLoja({
                        ...loja,
                        nome_responsavel: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Telefone da Loja"
                    variant="outlined"
                    value={loja?.telefone_loja || ""}
                    onChange={(e) =>
                      setLoja({
                        ...loja,
                        telefone_loja: e.target.value,
                      })
                    }
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Grupo</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={loja.id_grupo_loja || ""}
                      label="Grupo"
                      onChange={(e) =>
                        setLoja({
                          ...loja,
                          id_grupo_loja: e.target.value,
                        })
                      }
                    >
                      {grupos.map((group) => (
                        <MenuItem value={group.idgrupototens}>
                          {group.nomegrupo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </BodyCard>
            </Grid>
            <Grid xs={6}>
              <BodyCard>
                <HeaderCard>Configurações de Sistema</HeaderCard>
                <Stack gap={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={loja.status === "a"}
                          onChange={(e) =>
                            setLoja({
                              ...loja,
                              status: loja.status === "a" ? "i" : "a",
                            })
                          }
                        />
                      }
                      label={loja.status === "a" ? "Ativo" : "Inativo"}
                    />
                  </FormGroup>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Canal de Venda
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={getValueCanal() || ""}
                      label="Canal de Venda"
                      onChange={(e) => {
                        var newLoja = loja;
                        newLoja.cv_revenda = "N";
                        newLoja.cv_pbshop = "N";
                        newLoja.cv_engenharia = "N";
                        newLoja.cv_exportacao = "N";

                        switch (e.target.value) {
                          case "TOD":
                            newLoja.cv_revenda = "Y";
                            newLoja.cv_pbshop = "Y";
                            newLoja.cv_engenharia = "Y";
                            newLoja.cv_exportacao = "Y";
                            break;
                          case "REV":
                            newLoja.cv_revenda = "Y";
                            break;
                          case "PBS":
                            newLoja.cv_pbshop = "Y";
                            break;
                          case "ENG":
                            newLoja.cv_engenharia = "Y";
                            break;
                          case "EXP":
                            newLoja.cv_exportacao = "Y";
                            break;
                          default:
                            break;
                        }

                        setLoja({ ...loja, newLoja });
                      }}
                    >
                      <MenuItem value={"REV"}>Revenda</MenuItem>
                      <MenuItem value={"PBS"}>Portobello Shop</MenuItem>
                      <MenuItem value={"ENG"}>Engenharia</MenuItem>
                      <MenuItem value={"EXP"}>Exportação</MenuItem>
                      <MenuItem value={"TOD"}>Todos</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={loja.tipo || ""}
                      label="Tipo"
                      onChange={(e) =>
                        setLoja({ ...loja, tipo: e.target.value })
                      }
                    >
                      <MenuItem value={"totem"}>Totem</MenuItem>
                      <MenuItem value={"website"}>WebSite</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </BodyCard>
            </Grid>
            <Grid xs={6}>
              <BodyCard>
                <HeaderCard>Endereço da Loja</HeaderCard>
                <Stack gap={2}>
                  <TextField
                    label="CEP"
                    variant="outlined"
                    value={loja?.cep || ""}
                    onChange={handleEditCep}
                  />
                  <TextField
                    label="Endereço"
                    variant="outlined"
                    required
                    value={loja?.endereco || ""}
                    onChange={(e) =>
                      setLoja({ ...loja, endereco: e.target.value })
                    }
                  />
                  <TextField
                    label="Cidade"
                    variant="outlined"
                    required
                    value={loja?.cidade || ""}
                    onChange={(e) =>
                      setLoja({ ...loja, cidade: e.target.value })
                    }
                  />
                  <TextField
                    label="Estado"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    required
                    value={loja?.estado || ""}
                    onChange={(e) =>
                      setLoja({ ...loja, estado: e.target.value })
                    }
                  />
                </Stack>
              </BodyCard>
            </Grid>
            <Grid xs={6}>
              <BodyCard>
                <HeaderCard>Informações Consultor</HeaderCard>
                <Stack gap={2}>
                  <TextField
                    label="Nome"
                    variant="outlined"
                    required
                    value={loja?.consultor_nomes || ""}
                    onChange={(e) =>
                      setLoja({
                        ...loja,
                        consultor_nomes: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Telefone"
                    variant="outlined"
                    required
                    value={loja?.consultor_telefone || ""}
                    onChange={(e) =>
                      setLoja({
                        ...loja,
                        consultor_telefone: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    value={loja?.consultor_email || ""}
                    onChange={(e) =>
                      setLoja({
                        ...loja,
                        consultor_email: e.target.value,
                      })
                    }
                  />
                </Stack>
              </BodyCard>
            </Grid>
          </Grid>

          <Box
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <Stack direction={"row"} gap={2}>
              <Button
                variant="outlined"
                color="error"
                startIcon={<Cancel />}
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
              <Button
                variant="outlined"
                color="success"
                type="submit"
                startIcon={<Check />}
              >
                Confirmar
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </PageBase>
  );
}
