import React from "react";
import PageBase from "../components/PageBase";
import { Container } from "@mui/material";

export default function HomePage() {
  return (
    <PageBase title="Home" loading={false}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <h3>Painel Administrativo</h3>
        <h4>Digital Portobello</h4>
      </Container>
    </PageBase>
  );
}
