import { Add, Delete, Edit, Rule } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  ptBR,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../api/services";
import PageBase from "../components/PageBase";
import QuestionDialog from "../components/QuestionDialog";

export default function ListShopsPage() {
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    api.get("/api/v1/lojas").then((response) => {
      setRows(response.data.results);
    });
  }, []);

  const deleteLoja = (id) => {
    api
      .delete("/api/v1/loja/" + id)
      .then((res) => {
        toast.success("Loja excluída com sucesso!");
        api.get("/api/v1/lojas").then((response) => {
          setRows(response.data.results);
        });
      })
      .catch((err) => console.error(err));
  };

  const handleEditClick = (id) => () => {
    navigate("edit/" + id, { state: { id: id } });
  };

  const handleRulesClick = (id) => () => {
    navigate("edit/" + id + "/rules", { state: { id: id } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    deleteLoja(id);
  };

  const handleAddNew = () => {
    navigate("register");
  };

  const handleDeleteMarkedClick = () => {
    setRows(
      rows.filter(
        (row) => selectionModel.findIndex((it) => it === row.totem_id) === -1
      )
    );

    selectionModel.forEach((it) => {
      deleteLoja(it);
    });
  };

  const columns = [
    { field: "totem_id", headerName: "ID", width: 60 },
    {
      field: "nome_da_loja",
      headerName: "Nome",
      width: 350,
      disableColumnMenu: true,
    },
    {
      field: "estado",
      headerName: "Estado",
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: "nome_responsavel",
      headerName: "Responsável",
      width: 250,
      disableColumnMenu: true,
    },
    {
      field: "group_name",
      headerName: "Nome Grupo",
      width: 250,
      disableColumnMenu: true,
    },

    {
      field: "status",
      headerName: "Syncro Ativo",
      valueGetter: ({ value }) => value === "a",
      type: "boolean",
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 100,
      disableColumnMenu: true,
    },

    {
      headerName: "Ações",
      field: "actions",
      type: "actions",
      width: 140,
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar">
              <Edit />
            </Tooltip>
          }
          label="Editar"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Editar Regras">
              <Rule />
            </Tooltip>
          }
          label="Editar Regras"
          onClick={handleRulesClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={
            <QuestionDialog
              title="Excluir a loja"
              description="Tem certeza que deseja excluir a loja?"
              onConfirm={handleDeleteClick(id)}
            >
              <Tooltip title="Excluir Item">
                <Delete />
              </Tooltip>
            </QuestionDialog>
          }
          label="Delete"
          color="inherit"
        />,
      ],
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ m: 1, gap: 2 }}>
        <Button
          variant="outlined"
          color="success"
          startIcon={<Add />}
          onClick={handleAddNew}
        >
          Adicionar Novo
        </Button>
        <QuestionDialog
          title="Excluir as lojas marcadas"
          description="Tem certeza que deseja excluir as lojas marcadas?"
          onConfirm={() => handleDeleteMarkedClick()}
        >
          <Button variant="outlined" color="error" startIcon={<FaTimes />}>
            Excluir Marcados
          </Button>
        </QuestionDialog>
        <GridToolbarFilterButton variant={"contained"} />
        {/* <GridToolbarDensitySelector /> */}
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  return (
    <PageBase title="Lojas" loading={rows === undefined || rows.length === 0}>
      <DataGrid
        rows={rows}
        autoHeight
        columns={columns}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        components={{
          Toolbar: CustomToolbar,
        }}
        getRowId={(it) => it.totem_id}
        // pageSize={10}
        // rowsPerPageOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          "& .MuiTablePagination-toolbar p": {
            marginBottom: "0",
          },
        }}
      />
    </PageBase>
  );
}
