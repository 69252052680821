// Import de bibliotecas e Dependências
import React from "react";

/**
 * @brief Tela de erro para links não encontrados
*/
export default function NoMatch(){
  return(
    <>
    <div className="Mensagem">
      ERROR 404: PAGE NOT FOUND
    </div>
    </>
  );
}