import { Delete, Download, Edit, Save, Upload } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsFilePdf, BsYoutube } from "react-icons/bs";
import api from "../api/services";
import PageBase from "../components/PageBase";

const tabs = [
  { label: "Catálogos e Livros", value: 0 },
  { label: "Dicas Portobello", value: 1 },
  { label: "Manuais Informativos", value: 2 },
  { label: "Manual Digital", value: 3 },
  { label: "Norma de Desempenho", value: 4 },
  { label: "Sistemas Portobello", value: 5 },
  { label: "Vídeos", value: 6 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TechLibraryPage() {
  const [actualTabIndex, setActualTabIndex] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    api.get("/api/v1/arquivos").then((response) => {
      var items = response.data.results;
      items.forEach((item) => {
        item.inEdition = false;
      });

      setFiles(items);
      setLoading(false);
    });
  }, []);

  const handleAddNewItem = (type) => {
    if (files.some((it) => it.id === 0 + "" + actualTabIndex)) {
      toast.error("Você já possui um novo item.");
    } else {
      setFiles([
        ...files,
        {
          id: 0 + "" + actualTabIndex,
          id_aba: actualTabIndex,
          title: "",
          description: "",
          url: "",
          inEdition: true,
          tipo: type,
        },
      ]);
    }
  };

  const handleDeleteFile = (file) => {
    api.delete("/api/v1/arquivo/" + file.id);
    setFiles(files.filter((it) => it.id !== file.id));
  };

  return (
    <PageBase title="Biblioteca Técnica" loading={loading}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={actualTabIndex}
            onChange={(event, newValue) => setActualTabIndex(newValue)}
          >
            {tabs.map((tab) => (
              <Tab label={tab.label} {...a11yProps(tab.value)}></Tab>
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab) => (
          <TabPanel value={actualTabIndex} index={tab.value}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(300px, .28fr))"
              // gridTemplateRows="repeat(auto-fit, minmax(100px, 350px))"
              gap={2}
            >
              <Box>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack
                    spacing={3}
                    direction="row"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Stack spacing={1} direction="column">
                      <IconButton
                        size="large"
                        sx={{ padding: 3 }}
                        onClick={() => handleAddNewItem("pdf")}
                      >
                        <Typography paddingRight={3}>Novo PDF</Typography>
                        <BsFilePdf fontSize="70" />
                      </IconButton>
                      <IconButton
                        size="large"
                        sx={{ padding: 3 }}
                        onClick={() => handleAddNewItem("video")}
                      >
                        <Typography paddingRight={3}>Novo Vídeo</Typography>
                        <BsYoutube fontSize="70" />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Card>
              </Box>
              {files
                .filter((it) => it.id_aba === actualTabIndex)
                .map((file, index) => (
                  <Box>
                    <Card sx={{ width: "100%", height: "100%" }} elevation={10}>
                      <CardContent>
                        <Stack
                          spacing={2}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <TextField
                            sx={{ width: "100%" }}
                            size="small"
                            id="outlined-basic"
                            label="Nome"
                            value={file.title}
                            onChange={(e) => {
                              setFiles(
                                files.map((it, i) =>
                                  it.id === file.id
                                    ? { ...it, title: e.target.value }
                                    : it
                                )
                              );
                            }}
                            variant="outlined"
                            disabled={!file.inEdition}
                          />
                          {file.tipo === "video" ? (
                            <iframe
                            title="Vídeo Digital"
                              width="100%"
                              src={
                                "https://www.youtube.com/embed/" +
                                file.url.split("=")[1]
                              }
                            ></iframe>
                          ) : (
                            <object
                              data={file.url}
                              tipo="application/pdf"
                              width="100%"
                              height="100%"
                            ></object>
                          )}

                          <TextField
                            sx={{ width: "100%" }}
                            size="small"
                            id="outlined-basic"
                            label="Url"
                            value={file.url}
                            variant="outlined"
                            onChange={(e) => {
                              setFiles(
                                files.map((it) =>
                                  it.id === file.id
                                    ? { ...it, url: e.target.value }
                                    : it
                                )
                              );
                            }}
                            disabled={!file.inEdition || file.tipo === "pdf"}
                          />
                        </Stack>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "end" }}>
                        {file.tipo === "pdf" ? (
                          <>
                            <input
                              accept="*.pdf"
                              type="file"
                              id={"upload-photo" + file.id}
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  setFiles(
                                    files.map((it) =>
                                      it.id === file.id
                                        ? {
                                            ...it,
                                            file: e.target.files[0],
                                            url: URL.createObjectURL(
                                              e.target.files[0]
                                            ),
                                          }
                                        : it
                                    )
                                  );
                                }
                              }}
                              style={{ display: "none" }}
                            />
                            <IconButton
                              onClick={() => {
                                document
                                  .getElementById("upload-photo" + file.id)
                                  .click();
                              }}
                            >
                              <Upload />
                            </IconButton>
                            <IconButton
                              disabled={file.url === "" || file.url === null}
                            >
                              <Download />
                            </IconButton>
                          </>
                        ) : (
                          <></>
                        )}
                        {file.inEdition === true ? (
                          <IconButton
                            onClick={async (e) => {
                              var urlToSet = file.url;

                              if (
                                Object.hasOwn(file, "file") &&
                                file.file != null &&
                                file.file !== undefined
                              ) {
                                await api
                                  .post(
                                    "/api/v1/upload_file",
                                    { file: file.file },
                                    {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    toast.success(
                                      "Arquivo enviada com sucesso!"
                                    );
                                    urlToSet = res.data.path;
                                  })
                                  .catch((err) => console.log(err));
                              }

                              api
                                .post("/api/v1/arquivos", {
                                  title: file.title,
                                  id_aba: file.id_aba,
                                  description: file.description,
                                  url: urlToSet,
                                  tipo: file.tipo,
                                })
                                .then((res) => {
                                  setFiles(
                                    files.map((it) =>
                                      it.id === file.id
                                        ? { ...res.data, inEdition: false }
                                        : it
                                    )
                                  );
                                });
                            }}
                          >
                            <Save />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              setFiles(
                                files.map((it) =>
                                  it.id === file.id
                                    ? { ...it, inEdition: true }
                                    : it
                                )
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        <IconButton onClick={(e) => handleDeleteFile(file)}>
                          <Delete />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Box>
                ))}
            </Box>
          </TabPanel>
        ))}
      </Box>
    </PageBase>
  );
}
