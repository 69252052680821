import React from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

import LoginPage from "./pages/LoginPage";

import {
  Group,
  Image,
  ImageAspectRatio,
  LibraryBooks,
  Person,
  Place,
  ProductionQuantityLimits,
  Shop2,
  VideoCall,
} from "@mui/icons-material";

import { getRole, isAuthenticated } from "./api/auth";
import BannersPage from "./pages/BannersPage";
import EditUserPage from "./pages/EditUserPage";
import EnvinromentsPage from "./pages/EnvinromentsPage";
import ListGroupRulesPage from "./pages/ListGroupRulesPage";
import ListGroupsPage from "./pages/ListGroupsPage";
import ListProductsPage from "./pages/ListProductsPage";
import ListShopsPage from "./pages/ListShopsPage";
import ListUsersPage from "./pages/ListUsersPage";
import MiniBannersPage from "./pages/MiniBannersPage";
import RegisterShopPage from "./pages/RegisterShopPage";
import ShopEditPage from "./pages/ShopEditPage";
import ShopRulesPage from "./pages/ShopRulesPage";
import TechLibraryPage from "./pages/TechLibraryPage";
import VideosPage from "./pages/VideosPage";
import HomePage from "./pages/HomePage";

export default function PrivateRoute({ children, roles }) {
  const role = getRole();
  if (isAuthenticated() && !roles.includes(parseInt(role))) {
    Swal.fire({
      title: "Erro!",
      text: "Usuário não possui acesso!",
      icon: "error",
    });

    return <Navigate to="/" />;
  }
  if (!isAuthenticated()) {
    Swal.fire({
      title: "Erro!",
      text: "Usuário ou senha incorretos!",
      icon: "error",
    });

    return <Navigate to="/" />;
  }
  return children;
}

export const routes = [
  {
    id: 0,
    path: "/",
    element: <LoginPage />,
    private: false,
    name: "Login",
  },
  {
    id: 1,
    path: "/users",
    element: <ListUsersPage />,
    roles: [1, 3],
    private: true,
    name: "Cadastro de Usuários",
    icon: <Person />,
    sideBar: true,
  },
  {
    id: 2,
    path: "/users/register",
    element: <EditUserPage />,
    roles: [1, 3],
    private: true,
    name: "Cadastro de Usuários",
  },
  {
    id: 3,
    path: "/users/edit/:id",
    element: <EditUserPage />,
    roles: [1, 3],
    private: true,
    name: "Edição de Usuários",
  },
  {
    id: 4,
    path: "/products",
    element: <ListProductsPage />,
    roles: [1],
    private: true,
    name: "Produtos",
    icon: <ProductionQuantityLimits />,
    sideBar: true,
  },
  {
    id: 5,
    path: "/shops",
    element: <ListShopsPage />,
    roles: [1, 3],
    private: true,
    name: "Cadastro de Lojas",
    icon: <Shop2 />,
    sideBar: true,
  },
  {
    id: 6,
    path: "/shops/register",
    element: <RegisterShopPage />,
    roles: [1, 3],
    name: "Cadastro de Lojas",
    private: true,
  },
  {
    id: 7,
    path: "/shops/edit/:id",
    element: <ShopEditPage />,
    roles: [1, 3],
    name: "Edição de Lojas",
    private: true,
  },
  {
    id: 8,
    path: "/shops/edit/:id/rules",
    element: <ShopRulesPage />,
    roles: [1, 3],
    name: "Regras de Lojas",
    private: true,
  },
  {
    id: 9,
    path: "/groups",
    element: <ListGroupsPage />,
    roles: [1, 3],
    name: "Cadastro de Grupos",
    icon: <Group />,
    sideBar: true,
  },
  {
    id: 10,
    path: "/groups/edit/:id/rules",
    element: <ListGroupRulesPage />,
    roles: [1, 3],
    name: "Regras de Grupos",
    private: true,
  },
  {
    id: 11,
    path: "/banners",
    element: <BannersPage />,
    roles: [1, 3],
    name: "Banners",
    icon: <Image />,
    sideBar: true,
  },
  {
    id: 12,
    path: "/mini-banners",
    element: <MiniBannersPage />,
    roles: [1, 3],
    name: "Mini Banners",
    icon: <ImageAspectRatio />,
    sideBar: true,
  },
  {
    id: 13,
    path: "/videos",
    element: <VideosPage />,
    roles: [1, 3],
    name: "Vídeo Descanso",
    icon: <VideoCall />,
    sideBar: true,
  },
  {
    id: 14,
    path: "/environments",
    element: <EnvinromentsPage />,
    roles: [1, 2],
    name: "Ambientes",
    icon: <Place />,
    sideBar: true,
  },
  {
    id: 15,
    path: "/tech-library",
    element: <TechLibraryPage />,
    roles: [1, 2, 3],
    name: "Biblioteca Técnica",
    icon: <LibraryBooks />,
    sideBar: true,
  },
  {
    id: 16,
    path: "/home",
    element: <HomePage />,
    roles: [1, 2, 3],
    name: "Home",
  },
];
