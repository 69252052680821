// Import de Bibliotecas
import React from "react";
import { Route, Routes } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import PrivateRoute, { routes } from "./routes";

import NoMatch from "./pages/NoMatchPage";

/**
 * @brief Corpo principal da aplicaçõa React. Através deste elemento, são roteadas as telas de acordo com a URL inserida pelo usuário no navegador.
 * @extends react-router-dom (V6) Utiliza da biblioteca react-router-dom na versão 6.x.x para poder gerenciar o roteamento, através da tag "Routes".
 */
function App() {
  return (
    <div className="App">
      <Toaster />
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            id={route.id}
            element={
              route.private ? (
                <PrivateRoute roles={route.roles}>{route.element}</PrivateRoute>
              ) : (
                route.element
              )
            }
          />
        ))}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default App;
