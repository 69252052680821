import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  ptBR,
} from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PageBase from "../components/PageBase";

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ m: 1 }}>
      <GridToolbarFilterButton variant={"contained"} />
    </GridToolbarContainer>
  );
}

export default function ListProductsPage() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://apipbhomog.portobello.com.br/rest/products?filter[MARCA]=Portobello&filter[ProdutoCanalVenda.canalVenda]=4&filter[FaseVida.codigo]=SP,AT&limit=1000&fields=COD_PRODUTO,DESC_PRODUTO,ProdutoCanalVenda.CanalVenda,FaseVida,dateUpdated",
        {
          headers: {
            Authorization: "Bearer 4bdae7b6da53e5e1e147dc37ea59f2e0ed71a36a",
          },
        }
      )
      .then((res) => {
        setRows(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      field: "COD_PRODUTO",
      headerName: "Código",
      width: 90,
      disableColumnMenu: true,
    },
    {
      field: "DESC_PRODUTO",
      headerName: "Descrição",
      width: 500,
      disableColumnMenu: true,
    },
    {
      field: "dateUpdated",
      headerName: "Data de Atualização",
      width: 200,
      type: "dateTime",
      disableColumnMenu: true,
    },
    {
      field: "ProdutoCanalVenda",
      headerName: "Canais de Venda",
      type: "actions",
      width: 500,
      renderCell: (params) => {
        return (
          <FormControl fullWidth>
            <Select
              multiple
              displayEmpty
              value={params.value?.map((it) => it.CanalVenda.nome)}
              onChange={() => {}}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <em>Canais</em>;
                }
                return selected?.join(", ");
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {params.value?.map((canal) => (
                <MenuItem
                  key={canal.CanalVenda.id}
                  value={canal.CanalVenda.nome}
                >
                  {canal.CanalVenda.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "FaseVida",
      headerName: "Fase de Vida",
      valueGetter: ({ value }) => value.codigo + " - " + value.descricao,
      width: 150,
      disableColumnMenu: true,
    },
  ];

  return (
    <PageBase
      title="Produtos"
      loading={rows === undefined || rows.length === 0}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(it) => it.COD_PRODUTO}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: CustomToolbar,
        }}
        sx={{
          "& .MuiTablePagination-toolbar p": {
            marginBottom: "0",
          },
        }}
      />
    </PageBase>
  );
}
