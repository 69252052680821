//Import de Bibliotecas e dependências
import React, { useEffect, useState } from "react";

import {
  Add,
  Delete,
  DragIndicator,
  Edit,
  ExpandMore,
  Save,
} from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Stack } from "@mui/system";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import api from "../api/services";
import PageBase from "../components/PageBase";
import QuestionDialog from "../components/QuestionDialog";

export default function BannersPage() {
  const [banners, setBanners] = useState();
  const [lojas, setLojas] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [lang, setLang] = useState("pt");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get("/api/v1/banners")
      .then((res) => {
        res.data.results.forEach(
          (it) =>
            (it.imagem =
              "https://sgudigital.portobello.com.br/images/" + it.imagem)
        );
        setBanners(res.data.results?.sort((a, b) => a.ordem - b.ordem));
      })
      .catch((err) => console.error(err));

    api
      .get("/api/v1/lojas")
      .then((res) => {
        setLojas(res.data.results);
      })
      .catch((err) => console.error(err));

    api
      .get("/api/v1/grupos")
      .then((res) => {
        setGrupos(res.data.results);
      })
      .catch((err) => console.error(err));

    return () => {};
  }, []);

  const getBanners = async () => {
    let response = await api
      .get("/api/v1/banners")
      .catch((err) => console.error(err));

    let newBanners = await response.data.results?.sort(
      (a, b) => a.ordem - b.ordem
    );

    newBanners.map(
      (e) =>
        (e.imagem = "https://sgudigital.portobello.com.br/images/" + e.imagem)
    );
    return newBanners;
  };

  function getIdsAplicacao(banner) {
    if (banner.ids_aplicacao === null || banner.ids_aplicacao === "") return [];

    if (banner.aplicacao === "G") {
      return grupos.filter(
        (it) =>
          banner.ids_aplicacao
            ?.split(" ")
            ?.indexOf(it.idgrupototens.toString()) !== -1
      );
    }
    if (banner.aplicacao === "L") {
      return lojas.filter(
        (it) =>
          banner.ids_aplicacao?.split(" ")?.indexOf(it.totem_id.toString()) !==
          -1
      );
    }
    return [];
  }

  function deleteBanner(bannersToRemove) {
    bannersToRemove.forEach((bannerToRemove) => {
      api
        .delete("/api/v1/banner/" + bannerToRemove.id)
        .then((res) => {
          toast.success("Banner removido com sucesso!");
        })
        .catch((err) => console.error(err));
    });
  }

  async function updateBanner(bannerToUpdate, index) {
    if (bannerToUpdate.imagem === undefined || bannerToUpdate.imagem === null) {
      toast.error("Para salvar selecione uma imagem antes");
      return;
    }

    if (
      bannerToUpdate.aplicacao !== "T" &&
      (bannerToUpdate.ids_aplicacao === "" ||
        bannerToUpdate.ids_aplicacao == null)
    ) {
      toast.error(
        "Se o campo de aplicação estiver preenchido, você tem que selecionar uma loja ou grupo!"
      );
      return;
    }

    const stringImg = bannerToUpdate.imagem.replaceAll(
      "https://sgudigital.portobello.com.br/images/",
      ""
    );

    const data = new FormData();

    data.append("id", bannerToUpdate.id);
    data.append("titulo", bannerToUpdate.titulo);
    data.append("texto", bannerToUpdate.texto);
    data.append("posicao", bannerToUpdate.posicao);
    data.append("ordem", index);
    // data.append("data_expiracao", bannerToUpdate.data_expiracao );
    data.append("status", bannerToUpdate.status);
    data.append("aplicacao", bannerToUpdate.aplicacao);
    data.append("ids_aplicacao", bannerToUpdate.ids_aplicacao);
    data.append("padrao", bannerToUpdate.padrao);
    data.append("idioma", bannerToUpdate.idioma);
    data.append("id_origem", bannerToUpdate.id_origem);
    data.append("imagem", stringImg);

    const imageData = new FormData();
    imageData.append("file", bannerToUpdate.imageFile);

    if (bannerToUpdate?.img_alterada) {
      let response = await api
        .post("/api/v1/upload", imageData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .catch((err) => console.log(err));
      toast.success("Imagem enviada com sucesso!");
      const img = response.data.path.replaceAll(
        "https://sgudigital.portobello.com.br/images/",
        ""
      );
      data.append("imagem", img);
    }

    if (bannerToUpdate.novo === true) {
      data.delete("id");
      let response = await api
        .post("/api/v1/banners", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .catch((err) => console.error(err));
      const indexItem = banners.indexOf(bannerToUpdate);
      setBanners([
        ...banners?.slice(0, indexItem),
        {
          ...banners[indexItem],
          id: response.data.id,
        },
        ...banners?.slice(indexItem + 1),
      ]);
    } else {
      await api
        .patch("/api/v1/banner/" + bannerToUpdate.id, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .catch((err) => console.error(err));
    }
  }

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    if (e.destination.droppableId !== e.source.droppableId) return;

    let tempData = Array.from(banners);
    let bannersSamePos = tempData.filter(
      (it) => it.posicao === e.source.droppableId
    );
    let bannersAnotherPos = tempData.filter(
      (it) => it.posicao !== e.source.droppableId
    );

    let [source_data] = bannersSamePos.splice(e.source.index, 1);
    bannersSamePos.splice(e.destination.index, 0, source_data);
    bannersSamePos.forEach((it, index) => (it.ordem = index));

    setBanners(bannersSamePos.concat(bannersAnotherPos));
  };

  const handleLang = (e, value) => {
    if (value != null) {
      setLang(value);
    }
  };

  const updateAllBanners = async (pos) => {
    var bannersToUpdate = banners.filter((it) => it.posicao === pos);
    for (let index = 0; index < bannersToUpdate.length; index++) {
      await updateBanner(bannersToUpdate[index], index);
    }
  };

  return (
    <PageBase
      title="Banners"
      loading={banners === undefined || banners.length === 0}
    >
      {loading ? (
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        ["cabeçalho", "rodapé"].map((pos) => (
          <div id={pos} key={pos}>
            <Accordion id={pos}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id={pos}
                component={Paper}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <h5>{pos === "cabeçalho" ? "SUPERIOR" : "INFERIOR"}</h5>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleLang}
                    aria-label="Idioma"
                  >
                    <ToggleButton value="pt">Português</ToggleButton>
                    <ToggleButton value="en">Inglês</ToggleButton>
                    <ToggleButton value="es">Espanhol</ToggleButton>
                  </ToggleButtonGroup>
                  <Button
                    variant="outlined"
                    color="success"
                    startIcon={<Add />}
                    onClick={(e) => {
                      e.preventDefault();
                      var newId = banners.length + 2;
                      while (
                        banners.findIndex((e) => e.id_origem == newId) !== -1
                      ) {
                        newId += 1;
                      }
                      debugger;
                      setBanners([
                        ...banners,
                        {
                          ordem: banners.length + 1,
                          posicao: pos,
                          status: "a",
                          titulo: "",
                          texto: "",
                          aplicacao: "T",
                          ids_aplicacao: "",
                          padrao: false,
                          idioma: "pt",
                          id_origem: newId,
                          novo: true,
                        },
                        {
                          ordem: banners.length + 1,
                          posicao: pos,
                          status: "a",
                          titulo: "",
                          texto: "",
                          aplicacao: "T",
                          ids_aplicacao: "",
                          padrao: false,
                          idioma: "en",
                          id_origem: newId,
                          novo: true,
                        },
                        {
                          ordem: banners.length + 1,
                          posicao: pos,
                          status: "a",
                          titulo: "",
                          texto: "",
                          aplicacao: "T",
                          ids_aplicacao: "",
                          padrao: false,
                          idioma: "es",
                          id_origem: newId,
                          novo: true,
                        },
                      ]);
                    }}
                  >
                    Adicionar Item
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Save />}
                    onClick={async (e) => {
                      setLoading(true);
                      await updateAllBanners(pos);
                      setLoading(false);
                      toast.success("Banners atualizados com sucesso!");
                    }}
                  >
                    Salvar Todos
                  </Button>
                </Stack>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "600px" }}
                >
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Table
                      stickyHeader
                      // sx={{ minWidth: 650 }}
                      // size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="center">Imagem</TableCell>
                          <TableCell></TableCell>
                          <TableCell>Aplicação</TableCell>
                          <TableCell>Regras</TableCell>
                          {/* <TableCell>Fixo</TableCell> */}
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <Droppable droppableId={pos} key={pos}>
                        {(provider) => (
                          <TableBody
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                          >
                            {banners
                              ?.filter(
                                (it) => it.posicao === pos && it.idioma === lang
                              )
                              .map((banner, index) => (
                                <Draggable
                                  key={index}
                                  draggableId={"" + banner.id}
                                  index={index}
                                >
                                  {(provider) => (
                                    <TableRow
                                      key={banner.id}
                                      // style={{ position: "relative" }}
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                    >
                                      <TableCell
                                        style={{ width: "1vw" }}
                                        {...provider.dragHandleProps}
                                      >
                                        <DragIndicator />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          height: "200px",
                                        }}
                                      >
                                        <Badge
                                          badgeContent={
                                            <div>
                                              <label htmlFor="upload-photo">
                                                <IconButton
                                                  color="inherit"
                                                  onClick={() => {
                                                    document
                                                      .getElementById(
                                                        "upload-photo" +
                                                          banner.id_origem +
                                                          banner.idioma
                                                      )
                                                      .click();
                                                  }}
                                                >
                                                  <Edit />
                                                </IconButton>
                                              </label>

                                              <input
                                                accept="image/*"
                                                type="file"
                                                id={
                                                  "upload-photo" +
                                                  banner.id_origem +
                                                  banner.idioma
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    e.target.files.length > 0
                                                  ) {
                                                    debugger;
                                                    const indexItem =
                                                      banners.indexOf(banner);
                                                    setBanners((prev) => {
                                                      const editedBanners =
                                                        prev.map(
                                                          (it, index) => {
                                                            return index ===
                                                              indexItem ||
                                                              (pos ===
                                                                "cabeçalho" &&
                                                                it.id_origem ===
                                                                  banner.id_origem)
                                                              ? {
                                                                  ...it,
                                                                  imagem:
                                                                    URL.createObjectURL(
                                                                      e.target
                                                                        .files[0]
                                                                    ),
                                                                  img_alterada:
                                                                    index ===
                                                                    indexItem,
                                                                  imageFile:
                                                                    e.target
                                                                      .files[0],
                                                                }
                                                              : it;
                                                          }
                                                        );
                                                      return editedBanners;
                                                    });
                                                  }
                                                }}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                          }
                                          color="default"
                                        >
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              // position: "relative",
                                              width: "10vw",
                                              height: "130px",
                                              border: "1px solid lightgray",
                                            }}
                                          >
                                            <img
                                              src={banner.imagem}
                                              alt={"Sem Imagem"}
                                              onClick={() =>
                                                window
                                                  .open(banner.imagem, "_blank")
                                                  .focus()
                                              }
                                              style={{
                                                flexShrink: 0,
                                                display: "flex",
                                                cursor: "pointer",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                objectFit: "cover",
                                                // minWidth: "100%",
                                                height: "100%",
                                              }}
                                            />
                                          </div>
                                        </Badge>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          minWidth: "15vw",
                                          // width: "450px",
                                        }}
                                      >
                                        <Stack gap={2}>
                                          <TextField
                                            id="outlined-basic"
                                            label="Título"
                                            size="small"
                                            onChange={(e) => {
                                              const indexItem =
                                                banners.indexOf(banner);
                                              setBanners((prev) => {
                                                const editedBanners = prev.map(
                                                  (it, index) => {
                                                    return indexItem === index
                                                      ? {
                                                          ...it,
                                                          titulo:
                                                            e.target.value,
                                                        }
                                                      : it;
                                                  }
                                                );
                                                return editedBanners;
                                              });
                                            }}
                                            value={banner.titulo}
                                          />
                                          <TextField
                                            id="outlined-multiline-static"
                                            label="Descrição"
                                            placeholder="Essa descrição aparecerá no canto inferior esquerdo sobre a imagem"
                                            multiline
                                            rows={3}
                                            value={banner.texto}
                                            onChange={(e) => {
                                              const indexItem =
                                                banners.indexOf(banner);
                                              setBanners((prev) => {
                                                const editedBanners = prev.map(
                                                  (it, index) => {
                                                    return indexItem === index
                                                      ? {
                                                          ...it,
                                                          texto: e.target.value,
                                                        }
                                                      : it;
                                                  }
                                                );
                                                return editedBanners;
                                              });
                                            }}
                                            size="small"
                                          />
                                        </Stack>
                                      </TableCell>
                                      <TableCell style={{ minWidth: "100px" }}>
                                        <FormControl fullWidth>
                                          <InputLabel id="aplicacao-label">
                                            Aplicação
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // style={{ maxWidth: "250px" }}
                                            value={banner.aplicacao || "T"}
                                            label="Aplicação"
                                            // disabled={banner.padrao}

                                            onChange={(e) => {
                                              const indexItem =
                                                banners.indexOf(banner);
                                              setBanners((prev) => {
                                                const editedBanners = prev.map(
                                                  (it, index) => {
                                                    return index === indexItem
                                                      ? {
                                                          ...it,
                                                          aplicacao:
                                                            e.target.value,
                                                          ids_aplicacao: "",
                                                        }
                                                      : it;
                                                  }
                                                );
                                                return editedBanners;
                                              });
                                            }}
                                          >
                                            <MenuItem value={"T"}>
                                              Todas as Lojas
                                            </MenuItem>
                                            <MenuItem value={"G"}>
                                              Grupos Escolhidos
                                            </MenuItem>
                                            <MenuItem value={"L"}>
                                              Lojas Escolhidas
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          minWidth: "250px",
                                          maxWidth: "450px",
                                        }}
                                      >
                                        <Autocomplete
                                          multiple
                                          id="tags-outlined"
                                          limitTags={3}
                                          filterSelectedOptions
                                          options={
                                            banner.aplicacao === "G"
                                              ? grupos
                                              : banner.aplicacao === "L"
                                              ? lojas
                                              : []
                                          }
                                          disabled={
                                            banner.aplicacao === "T" ||
                                            banner.aplicacao === null
                                          }
                                          getOptionLabel={(option) =>
                                            banner.aplicacao === "L"
                                              ? option.nome_da_loja
                                              : option.nomegrupo
                                          }
                                          value={getIdsAplicacao(banner)}
                                          onChange={(e, newValue) => {
                                            const indexItem =
                                              banners.indexOf(banner);
                                            setBanners([
                                              ...banners?.slice(0, indexItem),
                                              {
                                                ...banners[indexItem],
                                                ids_aplicacao:
                                                  banner.aplicacao === "G"
                                                    ? newValue
                                                        .map(
                                                          (it) =>
                                                            it.idgrupototens
                                                        )
                                                        .join(" ")
                                                    : newValue
                                                        .map(
                                                          (it) => it.totem_id
                                                        )
                                                        .join(" "),
                                              },
                                              ...banners?.slice(indexItem + 1),
                                            ]);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Regras"
                                            />
                                          )}
                                        />
                                      </TableCell>
                                      {/* <TableCell>
                                            <Tooltip title="O banner marcado como fixo irá aparecer sempre nos totens. Ele não pode ser excluído e deve ser aplicado a todas as lojas">
                                              <Radio
                                                checked={banner.padrao}
                                                color="default"
                                                onChange={() => {
                                                  const editedBanners =
                                                    banners.map((it) => {
                                                      return it.posicao !== pos
                                                        ? it
                                                        : banner.id === it.id
                                                        ? {
                                                            ...it,
                                                            padrao: true,
                                                            aplicacao: "T",
                                                            ids_aplicacao: "",
                                                          }
                                                        : {
                                                            ...it,
                                                            padrao: false,
                                                          };
                                                    });
                                                  setBanners(editedBanners);
                                                }}
                                              />
                                            </Tooltip>
                                          </TableCell> */}
                                      <TableCell>
                                        <Stack
                                          direction={"row"}
                                          justifyContent={"center"}
                                          spacing={2}
                                        >
                                          <QuestionDialog
                                            title="Excluir o item"
                                            description="Tem certeza que deseja excluir o item?"
                                            // disabled={banner.padrao}
                                            onConfirm={async () => {
                                              const oldBanners = banners.filter(
                                                (it) => it.id === undefined
                                              );
                                              var newBanners =
                                                await getBanners();
                                              newBanners = [
                                                ...newBanners,
                                                ...oldBanners,
                                              ];
                                              const indexItem =
                                                newBanners.indexOf(banner);
                                              const toRemove =
                                                newBanners.filter(
                                                  (it, index) =>
                                                    index === indexItem ||
                                                    (it.id_origem ===
                                                      banner.id_origem &&
                                                      pos === "cabeçalho")
                                                );
                                              debugger;
                                              setBanners(
                                                newBanners.filter(
                                                  (it) =>
                                                    toRemove.indexOf(it) === -1
                                                )
                                              );
                                              deleteBanner(toRemove);
                                            }}
                                          >
                                            <Tooltip title="Excluir Item">
                                              <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="label"
                                                // disabled={banner.padrao}
                                              >
                                                <Delete
                                                  sx={{ fontSize: 30 }}
                                                  color={"action"}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </QuestionDialog>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                            {provider.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    </Table>
                  </DragDropContext>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
        ))
      )}
    </PageBase>
  );
}
