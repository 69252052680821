import { Box, Button, Grid, Slider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaCheck, FaTimes } from "react-icons/fa";
import api from "../../api/services";
export default function ModalEditAmbiente({
  open,
  handleClose,
  ambient,
  handleChangeValue,
}) {
  const [ambientes, setAmbientes] = useState([]);

  const inputText = (values, field, ambiente) => {
    return (
      <TextField
        sx={{ width: "300px" }}
        label={"Observação"}
        value={values || undefined}
        onChange={(e) => {
          setAmbientes(
            ambientes.map((it) => {
              if (it.id === ambiente.id) {
                it[field] = e.target.value;
              }
              return it;
            })
          );
        }}
      />
    );
  };
  const inputPadrao = (values, field, reversed, ambiente) => {
    values = values.map((it, index) => ({
      value: index + 1,
      label: it,
    }));
    return (
      <Box sx={{ width: "300px" }}>
        <Slider
          track={reversed ? "inverted" : "normal"}
          // defaultValue={values[0].value || ""}
          min={values[0].value}
          max={values[values.length - 1].value}
          marks={values}
          valueLabelDisplay="off"
          value={
            values.find((it) => it.label === ambiente[field])?.value ||
            values
              .filter((it) => ambiente[field].includes(it.label))
              .map((it) => it.value) ||
            ""
          }
          onChange={(_, newValue) => {
            setAmbientes(
              ambientes.map((it) => {
                if (it.id === ambiente.id) {
                  it[field] = values.find((it) => it.value === newValue).label;
                }
                return it;
              })
            );
          }}
        />
      </Box>
    );
  };

  const inputRange = (values, field, second_field, ambiente) => {
    values = values.map((it, index) => ({
      value: index + 1,
      label: it,
    }));
    return (
      <Box sx={{ width: "300px" }}>
        <Slider
          track={"normal"}
          // defaultValue={values[0].value || ""}
          min={values[0].value}
          max={values[values.length - 1].value}
          disabled={
            ambiente.superficies_id === 2 &&
            field === "coeficiente_atrito_molhado"
          }
          marks={values}
          valueLabelDisplay="off"
          disableSwap
          value={
            values
              .filter(
                (it) =>
                  it.label === ambiente[field] ||
                  it.label === ambiente[second_field]
              )
              ?.map((it) => it.value) || ""
          }
          onChange={(_, newValue, activeThumb) => {
            if (!Array.isArray(newValue)) {
              return;
            }
            if (activeThumb === 0) {
              setAmbientes(
                ambientes.map((it) => {
                  if (it.id === ambiente.id) {
                    it[field] = values.find(
                      (it) => it.value === newValue[0]
                    ).label;
                  }
                  return it;
                })
              );
            } else {
              setAmbientes(
                ambientes.map((it) => {
                  if (it.id === ambiente.id) {
                    it[second_field] = values.find(
                      (it) => it.value === newValue[1]
                    ).label;
                  }
                  return it;
                })
              );
            }
          }}
        />
      </Box>
    );
  };

  const handleSave = () => {
    for (const it of ambientes) {
      const data = new FormData();

      data.append("coeficiente_atrito_molhado", it.coeficiente_atrito_molhado);
      data.append(
        "coeficiente_atrito_molhado_max",
        it.coeficiente_atrito_molhado_max
      );
      data.append("local_uso", it.local_uso);
      data.append("absorcao_agua", it.absorcao_agua);
      data.append("resistencia_manchas", it.resistencia_manchas);
      data.append("res_ata_quimico_alta", it.res_ata_quimico_alta);
      data.append("res_ata_quimico_baixa", it.res_ata_quimico_baixa);
      data.append("expansao_por_umidade", it.expansao_por_umidade);
      data.append("observacao_ambiente", it.observacao_ambiente || "");

      api
        .patch("/api/v1/ambienteN1/" + it.id, data)
        .catch((err) => console.error(err));
    }
    handleClose();
    toast.success("Atributos do ambiente atualizados com sucesso!");
  };

  useEffect(() => {
    if (
      ambient !== undefined &&
      ambient.ambientes_id !== undefined &&
      ambient.title !== undefined
    ) {
      api
        .get(
          `/api/v1/ambientesN1?ambientes_id=${ambient.ambientes_id}&title=${ambient.title}`
        )
        .then((response) => {
          setAmbientes(response.data.results);
        });
    }
  }, [ambient]);
  if (ambientes.length === 0) return;

  return (
    <Modal show={open} onHide={handleClose} dialogClassName="modalEditAmbiente">
      <Modal.Header closeButton>
        <Modal.Title>Regras do Ambiente - {ambient.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {ambientes.map((amb) => (
            <div key={amb.superficies_id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: 3,
                  fontWeight: "bold",
                  borderBottom: "1px solid gray",
                  marginBottom: "20px",
                }}
              >
                {amb.superficies_id === 1 ? "Piso" : "Parede"}
              </div>
              <Grid container spacing={2} style={{ margin: "5px" }}>
                <Grid xs={6}>
                  <div>Coeficiente de Atrito Molhado</div>
                </Grid>
                <Grid xs={6}>
                  {inputRange(
                    [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
                    "coeficiente_atrito_molhado",
                    "coeficiente_atrito_molhado_max",
                    amb
                  )}
                </Grid>

                <Grid xs={6}>
                  <div>Local de Uso</div>
                </Grid>
                <Grid xs={6}>
                  {inputPadrao(
                    amb.superficies_id === 1
                      ? ["RE", "CL", "CP", "IU"]
                      : ["RI", "PE", "FA"],
                    "local_uso",
                    true,
                    amb
                  )}
                </Grid>

                <Grid xs={6}>
                  <div>Máxima Absorção de Água</div>
                </Grid>
                <Grid xs={6}>
                  {inputPadrao(
                    [0, 0.1, 0.5, 3, 6, 10, 20],
                    "absorcao_agua",
                    false,
                    amb
                  )}
                </Grid>

                <Grid xs={6}>
                  <div>Mínima Resistência à Manchas</div>
                </Grid>
                <Grid xs={6}>
                  {inputPadrao(
                    [0, 1, 2, 3, 4, 5],
                    "resistencia_manchas",
                    true,
                    amb
                  )}
                </Grid>
                {/* 
                <Grid xs={6}>
                  <div>Mínima R.A.Q. Alta Concentração</div>
                </Grid>
                <Grid xs={6}>
                  {inputPadrao(
                    ["HA", "HB", "HC"],
                    "res_ata_quimico_alta",
                    false,
                    amb
                  )}
                </Grid> */}

                <Grid xs={6}>
                  <div>Mínima R.A.Q. Baixa Concentração</div>
                </Grid>
                <Grid xs={6}>
                  {inputPadrao(
                    ["0", "LA", "LB", "LC"],
                    "res_ata_quimico_baixa",
                    false,
                    amb
                  )}
                </Grid>

                {/* <Grid xs={6}>
                <div>Mínima Res. Produtos de Limpeza</div>
                </Grid>
                <Grid xs={6}>{inputPadrao(["A", "B", "C"],ambient., false)}</Grid> */}

                <Grid xs={6}>
                  <div>Máxima Expansão por Umidade</div>
                </Grid>
                <Grid xs={6}>
                  {inputPadrao(
                    [0, 0.2, 0.3, 0.4, 0.5, 0.6],
                    "expansao_por_umidade",
                    false,
                    amb
                  )}
                </Grid>
                {/* <Grid
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>Acabamento de Superfície</div>
                </Grid>
                <Grid xs={6}>
                  <FormGroup>
                    <Box sx={{ width: "300px" }}>
                      {["MATE", "POL", "EXT", "NAT", "BRILHO", "TACT"].map(
                        (acab) => (
                          <FormControlLabel
                            key={acab}
                            control={
                              <Checkbox
                                checked={
                                  amb.acabamento_superficie?.includes(acab) ||
                                  false
                                }
                                onChange={(event) => {
                                  amb.acabamento_superficie =
                                    amb.acabamento_superficie === null
                                      ? ""
                                      : amb.acabamento_superficie;

                                  var newAcabamento =
                                    amb.acabamento_superficie.split(" ");

                                  if (event.target.checked) {
                                    newAcabamento.push(acab);
                                  } else {
                                    newAcabamento = newAcabamento.filter(
                                      (it) => it !== acab
                                    );
                                  }

                                  setAmbientes(
                                    ambientes.map((it) => {
                                      if (it.id === amb.id) {
                                        it.acabamento_superficie =
                                          newAcabamento.join(" ");
                                      }
                                      return it;
                                    })
                                  );
                                }}
                                name={acab}
                              />
                            }
                            label={acab}
                          />
                        )
                      )}
                    </Box>
                  </FormGroup>
                </Grid> */}
                <Grid xs={6}>
                  <div>Observações</div>
                </Grid>
                <Grid xs={6}>
                  {inputText(
                    amb.observacao_ambiente,
                    "observacao_ambiente",
                    amb
                  )}
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", gap: "5px" }}>
        <Button
          variant="outlined"
          color="error"
          startIcon={<FaTimes />}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="success"
          startIcon={<FaCheck />}
          onClick={handleSave}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
