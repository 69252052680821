import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaCheck, FaTimes } from "react-icons/fa";
export default function ModalChangeAmbienteName({
  open,
  handleClose,
  ambient,
  handleChangeValue,
}) {
  const [nome, setNome] = useState("");
  useEffect(() => {
    if (ambient !== undefined) {
      setNome(ambient.title);
    }
  }, [ambient]);

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Alterar Nome do Ambiente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextField
          label="Nome"
          variant="outlined"
          value={nome}
          fullWidth
          onChange={(e) => setNome(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", gap: "5px" }}>
        <Button
          variant="outlined"
          color="error"
          startIcon={<FaTimes />}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="success"
          startIcon={<FaCheck />}
          onClick={(e) => {
            handleChangeValue(ambient, nome);
            toast.success("Nome dos ambientes alterados com sucesso!");
            handleClose();
          }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
