import { Delete, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Stack, Typography
} from "@mui/material";
import React from "react";
import IOSSwitch from "./IOSSwitch";
import QuestionDialog from "./QuestionDialog";

export default function ItemListAmbientes({
  expanded,
  handleChangeExpanded,
  ambient,
  handleChangeStatus,
  handleDelete,
  children,
  isAmbient,
}) {
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChangeExpanded(ambient.id)}
      key={ambient.id}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        component={Paper}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography>{ambient.title}</Typography>
          <Stack direction="horizontal">
            <FormGroup sx={{ width: "120px" }}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={ambient.ativo === "a"}
                    onChange={() => handleChangeStatus(ambient)}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                  />
                }
                label={ambient.ativo === "a" ? "Ativo" : "Inativo"}
              />
            </FormGroup>
            {/* {isAmbient && (
              <Tooltip title="Mudar Ambiente">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onFocus={(event) => event.stopPropagation()}
                >
                  <EditAttributes />
                </IconButton>
              </Tooltip>
            )} */}

            <QuestionDialog
              title="Excluir ambiente"
              description="Tem certeza que deseja excluir o ambiente?"
              onConfirm={() => handleDelete(ambient)}
            >
              <IconButton
                onClick={(event) => {}}
                onFocus={(event) => event.stopPropagation()}
                onMouseOver={(event) => event.stopPropagation()}
                sx={{ marginRight: "10px" }}
              >
                <Delete />
              </IconButton>
            </QuestionDialog>
          </Stack>
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
