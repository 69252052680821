import styled from "@emotion/styled";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../api/services";
import IOSSwitch from "../components/IOSSwitch";
import ItemListAmbientes from "../components/ItemListAmbientes";
import ModalChangeAmbienteName from "../components/Modals/ModalChangeAmbienteName";
import ModalCreateAmbiente from "../components/Modals/ModalCreateAmbiente";
import ModalEditAmbiente from "../components/Modals/ModalEditAmbiente";
import PageBase from "../components/PageBase";
import QuestionDialog from "../components/QuestionDialog";

export default function EnvinromentsPage() {
  const [usos, setUsos] = useState([]);
  const [ambientes, setAmbientes] = useState([]);
  const [ambientesN1, setAmbientesN1] = useState([]);
  const [usoExpanded, setUsoExpanded] = useState(false);
  const [ambienteExpanded, setAmbienteExpanded] = useState(false);
  const [ambienteEscolhido, setAmbienteEscolhido] = useState({});
  // const [usoEscolhido, setUsoEscolhido] = useState({});
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openChangeName, setOpenChangeName] = useState(false);
  // const [openChangeAmbiente, setOpenChangeAmbiente] = useState(false);

  const arrayUniqueByKey = (array, key) => {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  };

  useEffect(() => {
    api.get("/api/v1/usos").then((response) => {
      setUsos(
        response.data.results.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        )
      );
    });
  }, [open]);

  const handleChangeUso = (panel) => async (event, isExpanded) => {
    setAmbientes([]);
    if (isExpanded) {
      await api.get("/api/v1/ambientes?uso_id=" + panel).then((response) => {
        setAmbientes(
          response.data.results.sort((a, b) =>
            a.title > b.title ? 1 : b.title > a.title ? -1 : 0
          )
        );
      });
    } else {
      setAmbientes([]);
    }
    setUsoExpanded(isExpanded ? panel : false);
  };

  const handleChangeAmbiente = (panel) => async (event, isExpanded) => {
    if (isExpanded) {
      await api
        .get("/api/v1/ambientesN1?ambientes_id=" + panel)
        .then((response) => {
          setAmbientesN1(
            arrayUniqueByKey(response.data.results, "title").sort((a, b) =>
              a.title > b.title ? 1 : b.title > a.title ? -1 : 0
            )
          );
        });
    } else {
      setAmbientesN1([]);
    }
    setAmbienteExpanded(isExpanded ? panel : false);
  };

  const handleChangeStatusUso = (obj) => {
    const statusNovo = obj.ativo === "a" ? "i" : "a";

    setUsos(
      usos.map((it) => {
        if (it.id === obj.id)
          return {
            ...it,
            ativo: statusNovo,
          };
        return it;
      })
    );

    api
      .patch("/api/v1/uso/" + obj.id, { ativo: statusNovo })
      .catch((err) => console.log(err));

    if (ambientes.length > 0) {
      setAmbientes(
        ambientes.map((it) => {
          return {
            ...it,
            ativo: statusNovo,
          };
        })
      );
    }
    if (ambientesN1.length > 0) {
      setAmbientesN1(
        ambientesN1.map((eb) => {
          return {
            ...eb,
            ativo: statusNovo,
          };
        })
      );
    }

    api.get("/api/v1/ambientes?uso_id=" + obj.id).then((response) => {
      for (const it of response.data.results) {
        api
          .patch("/api/v1/ambiente/" + it.id, { ativo: statusNovo })
          .catch((err) => console.log(err));
        api
          .get("/api/v1/ambientesN1?ambientes_id=" + it.id)
          .then((response) => {
            for (const ambn1 of response.data.results) {
              api
                .patch("/api/v1/ambienteN1/" + ambn1.id, { ativo: statusNovo })
                .catch((err) => console.log(err));
            }
          });
      }
    });
    toast.success("Status atualizado com sucesso");
  };

  const handleChangeStatusAmbiente = (obj) => {
    const statusNovo = obj.ativo === "a" ? "i" : "a";

    setAmbientes(
      ambientes.map((it) => {
        if (it.id === obj.id)
          return {
            ...it,
            ativo: statusNovo,
          };
        return it;
      })
    );

    api
      .patch("/api/v1/ambiente/" + obj.id, { ativo: statusNovo })
      .catch((err) => console.log(err));

    if (ambientesN1.length > 0) {
      setAmbientesN1(
        ambientesN1.map((eb) => {
          return {
            ...eb,
            ativo: statusNovo,
          };
        })
      );
    }

    api.get("/api/v1/ambientesN1?ambientes_id=" + obj.id).then((response) => {
      for (const ambn1 of response.data.results) {
        api
          .patch("/api/v1/ambienteN1/" + ambn1.id, { ativo: statusNovo })
          .catch((err) => console.log(err));
      }
    });

    toast.success("Status atualizado com sucesso");
  };

  const handleChangeStatusAmbienteN1 = (obj) => {
    const statusNovo = obj.ativo === "a" ? "i" : "a";

    setAmbientesN1(
      ambientesN1.map((it) => {
        if (it.id === obj.id)
          return {
            ...it,
            ativo: statusNovo,
          };
        return it;
      })
    );

    api
      .patch("/api/v1/ambienteN1/" + obj.id, { ativo: statusNovo })
      .catch((err) => console.log(err));
    toast.success("Status atualizado com sucesso");
  };

  const handleDeleteUso = (obj) => {
    setUsos(usos.filter((it) => it.id !== obj.id));
    api.delete("/api/v1/uso/" + obj.id).catch((err) => console.log(err));
    toast.success("Uso excluído com sucesso!");
  };

  const handleDeleteAmbiente = (obj) => {
    setAmbientes(ambientes.filter((it) => it.id !== obj.id));
    api.delete("/api/v1/ambiente/" + obj.id).catch((err) => console.log(err));
    toast.success("Ambiente excluído com sucesso!");
  };

  const handleDeleteAmbienteN1 = async (obj) => {
    setAmbientesN1(ambientesN1.filter((it) => it.id !== obj.id));
    await api
      .get("/api/v1/ambientesN1", {
        params: {
          ambientes_id: ambienteExpanded,
          title: obj.title,
        },
      })
      .then((response) => {
        for (const it of response.data.results) {
          api
            .delete("/api/v1/ambienteN1/" + it.id)
            .catch((err) => console.log(err));
        }
      });

    toast.success("Ambiente excluído com sucesso!");
  };

  const handleChangeValue = (amb, newValue, field) => {
    setAmbientesN1(
      ambientesN1.map((it) => {
        if (it.id === amb.id) return amb;
        return it;
      })
    );
  };

  const handleChangeNameAmbiente = (amb, newName) => {
    const oldName = amb.title;
    api.get("/api/v1/ambientesN1?title=" + oldName).then(async (res) => {
      for (const it of res.data.results) {
        const data = new FormData();
        data.append("title", newName);
        api.patch("/api/v1/ambienteN1/" + it.id, data);
      }
      await api
        .get("/api/v1/ambientesN1?ambientes_id=" + amb.ambientes_id)
        .then((response) => {
          setAmbientesN1(arrayUniqueByKey(response.data.results, "title"));
        });
    });
  };

  return (
    <PageBase title="Ambientes">
      <ModalEditAmbiente
        open={open}
        handleClose={() => setOpen(false)}
        ambient={ambienteEscolhido}
        handleChangeValue={handleChangeValue}
      />
      <ModalCreateAmbiente
        open={openCreate}
        handleClose={() => setOpenCreate(false)}
        handleOpen={() => setOpenCreate(true)}
      />
      {/* <ModalChangeAmbiente
        open={openChangeAmbiente}
        handleClose={() => setOpenChangeAmbiente(false)}
        usoObj={usoEscolhido}
        ambienteObj={ambienteEscolhido}
      /> */}
      <ModalChangeAmbienteName
        open={openChangeName}
        handleClose={() => setOpenChangeName(false)}
        ambient={ambienteEscolhido}
        handleChangeValue={handleChangeNameAmbiente}
      />
      <Box p={2}>
        <Button
          variant="outlined"
          startIcon={<Add />}
          color="success"
          onClick={() => setOpenCreate(true)}
        >
          ADICIONAR NOVO
        </Button>
      </Box>
      {usos.map((uso) => (
        <ItemListAmbientes
          key={uso.id}
          expanded={usoExpanded === uso.id}
          handleChangeExpanded={handleChangeUso}
          handleChangeStatus={handleChangeStatusUso}
          handleDelete={handleDeleteUso}
          ambient={uso}
          isAmbient={false}
        >
          {ambientes.map((ambiente) => (
            <ItemListAmbientes
              key={ambiente.id}
              expanded={ambienteExpanded === ambiente.id}
              handleChangeExpanded={handleChangeAmbiente}
              handleChangeStatus={handleChangeStatusAmbiente}
              handleDelete={handleDeleteAmbiente}
              ambient={ambiente}
              isAmbient={true}
            >
              {ambientesN1.map((ambN1) => (
                <Card key={ambN1.id}>
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Stack
                        direction="horizontal"
                        gap={2}
                        style={{ width: "90%" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Título"
                          variant="outlined"
                          value={ambN1.title}
                          sx={{ width: "800px" }}
                          onClick={(e) => e.stopPropagation()}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip>
                                  <IconButton
                                    onClick={() => {
                                      setAmbienteEscolhido(ambN1);
                                      setOpenChangeName(true);
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Descrição"
                          variant="outlined"
                          fullWidth
                          sx={{ marginRight: "20px" }}
                          value={ambN1.description}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            setAmbientesN1(
                              ambientesN1.map((it) => {
                                if (it.id === ambN1.id)
                                  it.description = e.target.value;
                                return it;
                              })
                            );
                          }}
                        />
                      </Stack>

                      <Stack direction="horizontal">
                        <FormGroup sx={{ width: "120px" }}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={ambN1.ativo === "a"}
                                onChange={() =>
                                  handleChangeStatusAmbienteN1(ambN1)
                                }
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                              />
                            }
                            label={ambN1.ativo === "a" ? "Ativo" : "Inativo"}
                          />
                        </FormGroup>
                        {/* <Tooltip title="Mudar Ambiente">
                                  <IconButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setUsoEscolhido(uso);
                                      setAmbienteEscolhido(ambiente);
                                      setOpenChangeAmbiente(true);
                                    }}
                                    onFocus={(event) => event.stopPropagation()}
                                  >
                                    <EditAttributes />
                                  </IconButton>
                                </Tooltip> */}
                        <Tooltip title="Editar">
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              setAmbienteEscolhido(ambN1);
                              setOpen(true);
                            }}
                            onFocus={(event) => event.stopPropagation()}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <QuestionDialog
                          title="Excluir o ambiente?"
                          description="Tem certeza que deseja excluir o ambiente?"
                          onConfirm={(ev) => {
                            handleDeleteAmbienteN1(ambN1);
                            ev.stopPropagation();
                          }}
                        >
                          <Tooltip title="Excluir">
                            <IconButton
                              onClick={(event) => {}}
                              onFocus={(event) => event.stopPropagation()}
                              onMouseOver={(event) => event.stopPropagation()}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </QuestionDialog>
                      </Stack>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </ItemListAmbientes>
          ))}
        </ItemListAmbientes>
      ))}
    </PageBase>
  );
}
