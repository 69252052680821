import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaCheck, FaTimes } from "react-icons/fa";
import api from "../../api/services";

const ambN1BasePiso = {
  title: "",
  description: "",
  ativo: "a",
  local_uso: "RE",
  coeficiente_atrito_molhado: [0, 0.8],
  absorcao_agua: 20,
  resistencia_manchas: 5,
  res_ata_quimico_alta: "HC",
  res_ata_quimico_baixa: "LC",
  expansao_por_umidade: 0.2,
  acabamento_superficie: null,
  observacao_ambiente: "",
};
const ambN1BaseParede = {
  title: "",
  description: "",
  ativo: "a",
  local_uso: "RI",
  coeficiente_atrito_molhado: [0, 0],
  absorcao_agua: 20,
  resistencia_manchas: 5,
  res_ata_quimico_alta: "HC",
  res_ata_quimico_baixa: "LC",
  expansao_por_umidade: 0.2,
  acabamento_superficie: null,
  observacao_ambiente: "",
};

export default function ModalCreateAmbiente({ open, handleClose, handleOpen }) {
  const [ambiente, setAmbiente] = useState();
  const [uso, setUso] = useState();
  const [ambientesN1, setAmbientesN1] = useState([]);
  const [usos, setUsos] = useState([]);
  const [ambientes, setAmbientes] = useState([]);
  const [newNome, setNewNome] = useState("");
  const [newAmbientes, setNewAmbientes] = useState([
    { ...ambN1BasePiso, superficie: 1, id: 1 },
    { ...ambN1BaseParede, superficie: 2, id: 2 },
  ]);
  const [superficie, setSuperficie] = useState(3);
  const [showCreateUso, setShowCreateUso] = useState(false);
  const [showCreateAmbiente, setShowCreateAmbiente] = useState(false);
  const [nomeNewUso, setNomeNewUso] = useState("");

  useEffect(() => {
    api.get("/api/v1/usos").then((response) => {
      setUsos(
        response.data.results.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        )
      );
    });
  }, [open]);

  const handleChangeUso = (event, newValue) => {
    if (newValue === undefined || newValue.id === 0) return;
    setUso(newValue);
    setAmbiente(null);
    setAmbientes([]);
    api.get("/api/v1/ambientes?uso_id=" + newValue.id).then((response) => {
      setAmbientes(
        response.data.results.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        )
      );
    });
  };

  const handleChangeAmbiente = (event, newValue) => {
    setAmbiente(newValue);
    api
      .get("/api/v1/ambientesN1?ambientes_id=" + newValue.id)
      .then((response) => {
        setAmbientesN1(response.data.results.map((it) => it.title));
      });
  };

  const handleChangeSuperficie = (event) => {
    const newValue = event.target.value;
    setSuperficie(newValue);
    if (newValue !== 3) {
      setNewAmbientes([
        { ...ambN1BasePiso, superficie: newValue, id: newValue },
      ]);
    } else {
      setNewAmbientes([
        { ...ambN1BasePiso, superficie: 1, id: 1 },
        { ...ambN1BaseParede, superficie: 2, id: 2 },
      ]);
    }
  };

  const handleSave = () => {
    if (uso === null || uso === undefined) {
      toast.error("Nenhum uso foi selecionado, selecione um");
      return;
    }

    if (ambiente === null || ambiente === undefined) {
      toast.error("Nenhum ambiente foi selecionado, selecione um");
      return;
    }

    if (newNome === "") {
      toast.error("Digite o nome do ambiente que deseja cadastrar");
      return;
    }
    if (newNome.includes(",")) {
      toast.error("Nome do Ambiente não pode conter vírgula");
      return;
    }

    for (const it of newAmbientes) {
      const data = {
        title: newNome,
        ativo: "a",
        coeficiente_atrito_molhado: it.coeficiente_atrito_molhado[0],
        coeficiente_atrito_molhado_max: it.coeficiente_atrito_molhado[1],
        local_uso: it.local_uso,
        absorcao_agua: it.absorcao_agua,
        resistencia_manchas: it.resistencia_manchas,
        res_ata_quimico_alta: it.res_ata_quimico_alta,
        res_ata_quimico_baixa: it.res_ata_quimico_baixa,
        expansao_por_umidade: it.expansao_por_umidade,
        acabamento_superficie: it.acabamento_superficie,
        ambientes_id: ambiente.id,
        superficies_id: it.superficie,
        observacao_ambiente: it.observacao_ambiente,
      };

      api
        .post("/api/v1/ambientesN1", data)
        .then((res) => {
          toast.success("Novo ambiente cadastrado com sucesso!");
          handleClose();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSaveUso = () => {
    if (nomeNewUso === "") {
      toast.error("Nome do Uso não pode estar vazio");
      return;
    } else if (nomeNewUso.includes(",")) {
      toast.error("Nome do Uso não pode conter vírgula");
      return;
    }
    api
      .post("/api/v1/usos", { title: nomeNewUso, description: "" })
      .then((res) => {
        setNomeNewUso("");
        setUsos([...usos, res.data]);
        toast.success("Novo uso cadastrado com sucesso!");
        setShowCreateUso(false);
        handleOpen();
      })
      .catch((err) => console.log(err));
  };

  const handleSaveAmbiente = () => {
    if (nomeNewUso === "") {
      toast.error("Nome do Ambiente não pode estar vazio");
      return;
    } else if (nomeNewUso.includes(",")) {
      toast.error("Nome do Ambiente não pode conter vírgula");
      return;
    }
    api
      .post("/api/v1/ambientes", {
        title: nomeNewUso,
        description: "",
        uso_id: uso.id,
      })
      .then((res) => {
        setNomeNewUso("");
        setAmbientes([...ambientes, res.data]);
        toast.success("Novo ambiente cadastrado com sucesso!");
        setShowCreateAmbiente(false);
        handleOpen();
      })
      .catch((err) => console.log(err));
  };
  const inputText = (values, field, ambiente) => {
    return (
      <TextField
        id="outlined-basic"
        label="Observação"
        variant="outlined"
        fullWidth
        sx={{ width: "15vw", marginRight: "20px" }}
        value={values || undefined}
        onChange={(e) => {
          setNewAmbientes(
            newAmbientes.map((it) => {
              if (it.id === ambiente.id) {
                it[field] = e.target.value;
              }
              return it;
            })
          );
        }}
        InputProps={{
          readOnly: false,
        }}
      />
    );
  };

  const inputPadrao = (values, field, reversed, amb) => {
    values = values.map((it, index) => ({
      value: index + 1,
      label: it,
    }));

    return (
      <Box sx={{ width: "15vw", marginRight: "20px" }}>
        <Slider
          track={reversed ? "inverted" : "normal"}
          defaultValue={values[0].value}
          min={values[0].value}
          max={values[values.length - 1].value}
          marks={values}
          disabled={
            amb.superficie === 2 && field === "coeficiente_atrito_molhado"
          }
          valueLabelDisplay="off"
          value={
            values.find((it) => it.label === amb[field])?.value ||
            values
              .filter((it) => amb[field].includes(it.label))
              .map((it) => it.value) ||
            ""
          }
          onChange={(_, newValue) => {
            setNewAmbientes(
              newAmbientes.map((it) => {
                if (it.id === amb.id) {
                  it[field] = values.find(
                    (value) => value.value === newValue
                  ).label;
                }
                return it;
              })
            );
          }}
        />
      </Box>
    );
  };

  if (usos.length === 0) return <></>;

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="modalCreateAmbiente"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Criação do Ambiente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <Stack direction="horizontal" gap={2}>
              <Stack direction="horizontal" gap={1} sx={{ width: 1 }}>
                <Autocomplete
                  disablePortal
                  clearOnEscape
                  includeInputInList
                  fullWidth
                  value={uso || null}
                  onChange={handleChangeUso}
                  isOptionEqualToValue={(op, va) => op.id === va.id}
                  getOptionLabel={(it) => it.label}
                  options={
                    usos?.map((it) => ({ label: it.title, id: it.id })) || []
                  }
                  // sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Usos" />
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={"Adicionar Uso"}>
                    <IconButton
                      color="success"
                      onClick={() => {
                        handleClose();
                        setNomeNewUso("");
                        setShowCreateUso(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>

              <Stack direction="horizontal" gap={1} sx={{ width: 1 }}>
                <Autocomplete
                  disablePortal
                  clearOnEscape
                  includeInputInList
                  fullWidth
                  disabled={ambientes.length === 0}
                  value={ambiente || null}
                  onChange={handleChangeAmbiente}
                  getOptionLabel={(it) => it.label}
                  isOptionEqualToValue={(op, va) => op.id === va.id}
                  options={
                    ambientes?.map((it) => ({ label: it.title, id: it.id })) ||
                    []
                  }
                  // sx={{ width: "20%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Ambientes" />
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={"Adicionar Ambiente"}>
                    <IconButton
                      disabled={uso === undefined}
                      color="success"
                      onClick={() => {
                        handleClose();
                        setNomeNewUso("");
                        setShowCreateAmbiente(true);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
              <Box sx={{ width: 1 }}>
                <TextField
                  // sx={{ width: "450px" }}
                  label="Novo Ambiente Específico"
                  variant="outlined"
                  fullWidth
                  value={newNome}
                  onChange={(e) => setNewNome(e.target.value)}
                  disabled={ambiente?.id === undefined}
                />
              </Box>
              <Box sx={{ width: 1 / 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="selectType">Superfície</InputLabel>
                  <Select
                    labelId="selectType"
                    id="selectTypes"
                    value={superficie}
                    label="Superfície"
                    onChange={handleChangeSuperficie}
                  >
                    <MenuItem value={1}>Piso</MenuItem>
                    <MenuItem value={2}>Parede</MenuItem>
                    <MenuItem value={3}>Piso e Parede</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <div style={{ display: "flex" }}>
              {newAmbientes.map((amb) => (
                <Box key={amb.superficie} p={1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: 3,
                      fontWeight: "bold",
                      borderBottom: "1px solid gray",
                      marginBottom: "20px",
                    }}
                  >
                    {amb.superficie === 1 ? "Piso" : "Parede"}
                  </div>
                  <Grid container spacing={3} style={{ margin: "5px" }}>
                    <Grid item xs={6}>
                      <div>Coeficiente de Atrito Molhado Mínimo</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputPadrao(
                        [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
                        "coeficiente_atrito_molhado",
                        false,
                        amb
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <div>Local de Uso</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputPadrao(
                        amb.superficie === 1
                          ? ["RE", "CL", "CP", "IU"]
                          : ["RI", "PE", "FA"],
                        "local_uso",
                        true,
                        amb
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <div>Máxima Absorção de Água</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputPadrao(
                        [0, 0.1, 0.5, 3, 6, 10, 20],
                        "absorcao_agua",
                        false,
                        amb
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <div>Mínima Resistência à Manchas</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputPadrao(
                        [0, 1, 2, 3, 4, 5],
                        "resistencia_manchas",
                        true,
                        amb
                      )}
                    </Grid>

                    {/* <Grid item xs={6}>
                      <div>Mínima R.A.Q. Alta Concentração</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputPadrao(
                        ["HA", "HB", "HC"],
                        "res_ata_quimico_alta",
                        false,
                        amb
                      )}
                    </Grid> */}

                    <Grid item xs={6}>
                      <div>Mínima R.A.Q. Baixa Concentração</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputPadrao(
                        ["0","LA", "LB", "LC"],
                        "res_ata_quimico_baixa",
                        false,
                        amb
                      )}
                    </Grid>

                    {/* <Grid item xs={6}>
                <div>Mínima Res. Produtos de Limpeza</div>
              </Grid>
              <Grid item xs={6}>{inputPadrao(["A", "B", "C"],ambient., false)}</Grid> */}

                    <Grid item xs={6}>
                      <div>Máxima Expansão por Umidade</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputPadrao(
                        [0, 0.2, 0.3, 0.4, 0.5, 0.6],
                        "expansao_por_umidade",
                        false,
                        amb
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <div>Observações</div>
                    </Grid>
                    <Grid item xs={6}>
                      {inputText(
                        amb.observacao_ambiente,
                        "observacao_ambiente",
                        amb
                      )}
                    </Grid>

                    {/* <Grid item xs={6}>
                      <div>Acabamento de Superfície</div>
                    </Grid>
                  <Grid xs={6}>
                  <FormGroup>
                    <Box sx={{ width: "300px" }}>
                      {["MATE", "POL", "EXT", "NAT", "BRILHO", "TACT"].map(
                        (acab) => (
                          <FormControlLabel
                            key={acab}
                            control={
                              <Checkbox
                                checked={
                                  amb.acabamento_superficie?.includes(acab) ||
                                  false
                                }
                                onChange={(event) => {
                                  amb.acabamento_superficie =
                                    amb.acabamento_superficie === null
                                      ? ""
                                      : amb.acabamento_superficie;

                                  var newAcabamento =
                                    amb.acabamento_superficie.split(" ");

                                  if (event.target.checked) {
                                    newAcabamento.push(acab);
                                  } else {
                                    newAcabamento = newAcabamento.filter(
                                      (it) => it !== acab
                                    );
                                  }

                                  setNewAmbientes(
                                    newAmbientes.map((it) => {
                                      if (it.id === amb.id) {
                                        it.acabamento_superficie =
                                          newAcabamento.join(" ");
                                      }
                                      return it;
                                    })
                                  );
                                }}
                                name={acab}
                              />
                            }
                            label={acab}
                          />
                        )
                      )}
                    </Box>
                  </FormGroup>
                </Grid> */}
                  </Grid>
                </Box>
              ))}
            </div>
          </Stack>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", gap: "5px" }}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<FaTimes />}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            color="success"
            startIcon={<FaCheck />}
            onClick={handleSave}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCreateUso}
        onHide={() => {
          setShowCreateUso(false);
          handleOpen();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Criar Novo Uso</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <TextField
            label="Nome"
            variant="outlined"
            value={nomeNewUso}
            fullWidth
            onChange={(e) => setNomeNewUso(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", gap: "5px" }}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<FaTimes />}
            onClick={() => {
              setShowCreateUso(false);
              handleOpen();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            color="success"
            startIcon={<FaCheck />}
            onClick={handleSaveUso}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCreateAmbiente}
        onHide={() => {
          setShowCreateAmbiente(false);
          handleOpen();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Criar Novo Ambiente</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <TextField
            label="Nome"
            variant="outlined"
            value={nomeNewUso}
            fullWidth
            onChange={(e) => setNomeNewUso(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", gap: "5px" }}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<FaTimes />}
            onClick={() => {
              setShowCreateAmbiente(false);
              handleOpen();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="outlined"
            color="success"
            startIcon={<FaCheck />}
            onClick={handleSaveAmbiente}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
