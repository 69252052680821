import React, { useState } from "react";
import PageBase from "../components/PageBase";

export default function MiniBannersPage() {
  const [miniBanners, setMiniBanners] = useState([]);

  return (
    <PageBase
      title="Mini Banners"
      loading={miniBanners === undefined || miniBanners.length === 0}
    >
     
    </PageBase>
  );
}
