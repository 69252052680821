import { Add, Cancel, Delete, Edit, Rule, Save } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarFilterButton,
  ptBR,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import api from "../api/services";
import QuestionDialog from "../components/QuestionDialog";
import PageBase from "../components/PageBase";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export default function ListGroupsPage() {
  const [userId, setUserId] = useState();
  const [rows, setRows] = useState();
  const [lojas, setLojas] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  const navigate = useNavigate();

  const columns = [
    {
      field: "idgrupototens",
      headerName: "ID",
      width: 90,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "nomegrupo",
      headerName: "Nome",
      width: 250,
      editable: true,
      disableColumnMenu: true,
    },
    {
      field: "nlojas",
      headerName: "Nº Lojas",
      type: "number",
      width: 110,
      align: "center",
      headerAlign: "center",
      // editable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.lojas?.length,
    },
    {
      field: "lojas",
      headerName: "Lojas",
      type: "actions",
      width: 550,
      renderCell: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (params.row.isNew !== undefined && params.row.isNew === true)
          return <></>;
        return (
          <FormControl fullWidth>
            <Select
              multiple
              displayEmpty
              value={params.value?.map((it) => it.nome_da_loja) || []}
              onChange={() => {}}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <em>Lojas</em>;
                }

                return selected?.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {lojas?.map((loja) => (
                <MenuItem
                  key={loja.totem_id}
                  value={loja.nome_da_loja}
                  disabled={!isInEditMode}
                >
                  <Checkbox
                    checked={
                      params.value
                        ?.map((it) => it.totem_id)
                        ?.indexOf(loja.totem_id) !== -1
                    }
                  />
                  {loja.nome_da_loja}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "status",
      headerName: "Ativo",
      type: "boolean",
      width: 110,
      editable: true,
      disableColumnMenu: true,
      valueGetter: ({ value }) => value === "a",
    },
    {
      headerName: "Ações",
      field: "actions",
      type: "actions",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              className="textPrimary"
              onClick={handleSaveClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Editar"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Rule />}
            label="Editar Regras"
            className="textPrimary"
            onClick={handleRulesClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <QuestionDialog
                title="Excluir o Grupo"
                description="Tem certeza que deseja excluir o grupo?"
                onConfirm={handleDeleteClick(id)}
              >
                <Tooltip title="Excluir Item">
                  <Delete />
                </Tooltip>
              </QuestionDialog>
            }
            label="Excluir"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const deleteGroup = (id) => {
    api
      .delete("/api/v1/grupo/" + id)
      .then((res) => {
        toast.success("Grupo excluído com sucesso!");
      })
      .catch((err) => console.error(err));
  };

  const getGrupos = () => {
    api.get("/api/v1/grupos").then(async (response) => {
      const grupos = response.data.results;
      var lojas = [];

      await api.get("/api/v1/lojas").then((response) => {
        lojas = response.data.results;
        setLojas(lojas);
        for (var grupo in grupos) {
          grupos[grupo].lojas = lojas.filter(
            (it) => it.id_grupo_loja == grupos[grupo].idgrupototens
          );
        }
        setRows(grupos);
      });
    });

    api.get("/api/v1/users").then((response) => {
      let userFiltered = response.data.results.filter(
        (opt) => opt.username === getCookie("username")
      );
      setUserId(userFiltered[0].id);
    });
  };

  // api.get("/api/v1/grupos").then(async (response) => {
  //   const grupos = response.data.results;
  //   var lojas = [];

  //   await api.get("/api/v1/lojas").then((response) => {
  //     lojas = response.data.results;
  //     setLojas(lojas);
  //     for (var grupo in grupos) {
  //       grupos[grupo].lojas = lojas.filter(
  //         (it) => it.id_grupo_loja == grupos[grupo].idgrupototens
  //       );
  //     }
  //     setRows(grupos);
  //   });
  // });
  // api.get("/api/v1/users").then((response) => {
  //   let userFiltered = response.data.results.filter(
  //     (opt) => opt.username === getCookie("username")
  //   );
  //   setUserId(userFiltered[0].id);
  // });

  useEffect(() => {
    getGrupos();
  }, []);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleRulesClick = (id) => () => {
    navigate("edit/" + id + "/rules", { state: { id: id } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.idgrupototens !== id));
    deleteGroup(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.idgrupototens === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.idgrupototens !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    newRow = {
      ...newRow,
      status: newRow.status === true || newRow.status === "a" ? "a" : "i",
    };
    if (newRow.isNew) {
      const data = new FormData();
      data.append("nomegrupo", newRow.nomegrupo);
      data.append("criadopor", userId);
      data.append("status", newRow.status);

      api
        .post("/api/v1/grupos", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          toast.success("Grupo criado com sucesso!");
          getGrupos();
        });
    } else {
      const data = new FormData();
      data.append("nomegrupo", newRow.nomegrupo);
      data.append("status", newRow.status);
      api
        .patch("/api/v1/grupo/" + newRow.idgrupototens, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          toast.success("Grupo atualizado com sucesso!");
          getGrupos();
        });
    }
    const updatedRow = { ...newRow, isNew: false };

    setRows(
      rows.map((row) =>
        row.idgrupototens === newRow.idgrupototens ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const handleAddNew = () => {
    const idgrupototens = 0;
    setRows((oldRows) => [
      ...oldRows,
      {
        idgrupototens,
        nomegrupo: "",
        status: "a",
        criadopor: userId,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [idgrupototens]: { mode: GridRowModes.Edit, fieldToFocus: "nomegrupo" },
    }));
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ m: 1 }}>
        <Button
          variant="outlined"
          color="success"
          startIcon={<Add />}
          onClick={handleAddNew}
          sx={{ marginRight: "10px" }}
        >
          Adicionar Novo
        </Button>
        <GridToolbarFilterButton variant={"contained"} />
        {/* <GridToolbarDensitySelector /> */}
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }
  return (
    <PageBase title="Grupos" loading={rows === undefined || rows?.length === 0}>
      <DataGrid
        rows={rows}
        columns={columns}
        density={"comfortable"}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        autoHeight
        getRowId={(it) => it.idgrupototens}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        hideFooterSelectedRowCount={true}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: CustomToolbar,
        }}
        sx={{
          "& .MuiTablePagination-toolbar p": {
            marginBottom: "0",
          },
        }}
      />
    </PageBase>
  );
}
