import styled from "@emotion/styled";
import { Cancel, Check } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/services";
import FormProgress from "../components/FormProgress";
import Sidebar from "../components/SideBar";
import { Colors } from "../styles/colors";

const Root = styled.div`
  display: flex;
`;

const RootBox = styled.div`
  background-color: ${Colors.background};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Section = styled(Card)`
  margin: 33px;
  width: 100%;
  background-color: white;
  /* border: 1px solid black; */
  border-radius: 8px;
`;

const Header = styled.div`
  background-color: white;
  padding: 20px;
  border-bottom: 2px solid whitesmoke;
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div``;

function not(a, b) {
  return a.filter(
    (it) => b.findIndex((el) => el.cod_produto === it.cod_produto) === -1
  );
}

function intersection(a, b) {
  return a.filter(
    (it) => b.findIndex((el) => el.cod_produto === it.cod_produto) !== -1
  );
}

export default function ShopRulesPage() {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [loja, setLoja] = useState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([
    { field: "cod_produto", value: "", side: "left" },
    { field: "desc_produto", value: "", side: "left" },
    { field: "cod_produto", value: "", side: "right" },
    { field: "desc_produto", value: "", side: "right" },
  ]);
  const locale = useLocation();
  const navigate = useNavigate();

  function getListFiltered(side, list) {
    const filtersSide = filters.filter(
      (it) => it.side === side && it.value !== ""
    );
    if (filtersSide.length === 0) return list;

    const filtered = list.filter((it) => {
      var retorno = 0;
      filtersSide.forEach((filter) => {
        if (
          it[filter.field]
            .toString()
            .toUpperCase()
            .includes(filter.value.toString().toUpperCase())
        )
          retorno += 1;
      });

      return retorno === filtersSide.length;
    });
    return filtered;
  }

  var leftFiltered =
    filters.findIndex((it) => it.side === "left" && it.value !== "") !== -1
      ? getListFiltered("left", left)
      : left;

  var rightFiltered =
    filters.findIndex((it) => it.side === "right" && it.value !== "") !== -1
      ? getListFiltered("right", right)
      : right;

  useEffect(() => {
    setLoading(true);
    api
      .get("/api/v1/loja/" + locale.state.id)
      .then((res) => setLoja(res.data))
      .catch((err) => console.log(err));

    api
      .get("/api/v1/rules/totens?id_loja=" + locale.state.id)
      .then((res) => {
        const rules = res.data.results;
        setRight(rules);
        axios
          .get(
            "https://apipb.portobello.com.br/rest/products?filter[FaseVida.codigo]=SP,AT&filter[MARCA]=Portobello&filter[COD_TIPOLOGIA_COMERCIAL]=14,5,2,1,13,65,67,72,74,3&filter[DamImages.type]=product&fields=COD_PRODUTO,DESC_PRODUTO",
            {
              headers: {
                Authorization:
                  "Bearer cd2f07998e38a09c8ae1ca6ea4291d58b27e1fc1",
              },
            }
          )
          .then((res) => {
            var products = res.data.result.map((it) => ({
              cod_produto: it.COD_PRODUTO,
              desc_produto: it.DESC_PRODUTO,
            }));

            products = products.filter(
              (it) =>
                rules.findIndex((el) => el.cod_produto === it.cod_produto) ===
                -1
            );
            setLeft(products);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err))
      .finally(setLoading(false));
  }, []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(rightFiltered.concat(leftFiltered));
    setLeft(not(left, leftFiltered));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(leftFiltered.concat(rightFiltered));
    setRight(not(right, rightFiltered));
  };

  const getValueFilter = (field, side) => {
    return (
      filters.find((it) => it.field === field && it.side === side)?.value || ""
    );
  };

  const handleChangeFilter = (field, side, newValue) => {
    setFilters(
      filters.map((it) => {
        if (it.side === side && it.field === field)
          return {
            ...it,
            value: newValue,
          };
        else return it;
      })
    );
  };

  async function saveAll() {
    setLoading(true);
    var itensToRemove = [];
    await api
      .get("/api/v1/rules/totens?id_loja=" + locale.state.id)
      .then((res) => (itensToRemove = res.data.results))
      .catch((err) => console.log(err));

    if (itensToRemove.length > 0)
      itensToRemove.forEach(async (it) => {
        await api
          .delete("/api/v1/rules/toten/" + it.id)
          .catch((err) => console.log(err));
      });
    for (const it of right) {
      api
        .post("/api/v1/rules/totens", {
          cod_produto: it.cod_produto,
          desc_produto: it.desc_produto,
          id_loja: locale.state.id,
        })
        .catch((err) => console.log(err));
    }

    setLoading(false);
    navigate(-1);
    toast.success("Regras da loja salvas com sucesso!");
  }

  const customList = (items) => (
    <Paper sx={{ width: "35vw", height: "58vh", overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((it) => {
          const labelId = `transfer-list-item-${it.id}-label`;

          return (
            <ListItemButton
              key={it.id}
              role="listitem"
              onClick={handleToggle(it)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(it) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${it.cod_produto} - ${it.desc_produto}`}
              />
            </ListItemButton>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Root>
      <Sidebar />
      <RootBox>
        {loja !== undefined && (
          <>
            <Section>
              <Header>
                <Typography variant="h4">{`Regras de Lojas - ${loja.nome_da_loja}`}</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<BiLeftArrowAlt />}
                  onClick={() => navigate(-1)}
                >
                  Voltar
                </Button>
              </Header>
              <Body>
                <Box sx={{ height: "87vh", width: "100%" }}>
                  {!loading ? (
                    <Stack>
                      <Grid
                        container
                        marginTop={1}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <Stack gap={2}>
                            <Card elevation={1}>
                              <Stack p={2} gap={1}>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Produtos Disponíveis
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{ fontStyle: "italic" }}
                                >
                                  Filtros
                                </Typography>
                                <Stack direction={"row"} gap={2}>
                                  <TextField
                                    id="outlined-basic"
                                    label="Código"
                                    variant="outlined"
                                    value={getValueFilter("id", "left")}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    onChange={(e) =>
                                      handleChangeFilter(
                                        "id",
                                        "left",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <TextField
                                    id="outlined-basic"
                                    label="Descrição"
                                    fullWidth
                                    variant="outlined"
                                    value={getValueFilter("name", "left")}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    onChange={(e) =>
                                      handleChangeFilter(
                                        "name",
                                        "left",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Stack>
                              </Stack>
                            </Card>
                            {customList(leftFiltered)}
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleAllRight}
                              disabled={left.length === 0}
                              aria-label="move all right"
                            >
                              ≫
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleCheckedRight}
                              disabled={leftChecked.length === 0}
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleCheckedLeft}
                              disabled={rightChecked.length === 0}
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleAllLeft}
                              disabled={right.length === 0}
                              aria-label="move all left"
                            >
                              ≪
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Stack gap={2}>
                            <Card elevation={1}>
                              <Stack p={2} gap={1}>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Produtos Removidos
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{ fontStyle: "italic" }}
                                >
                                  Filtros
                                </Typography>
                                <Stack direction={"row"} gap={2}>
                                  <TextField
                                    id="outlined-basic"
                                    label="Código"
                                    variant="outlined"
                                    value={getValueFilter("id", "right")}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    onChange={(e) =>
                                      handleChangeFilter(
                                        "id",
                                        "right",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <TextField
                                    id="outlined-basic"
                                    label="Descrição"
                                    fullWidth
                                    variant="outlined"
                                    value={getValueFilter(
                                      "desc_produto",
                                      "right"
                                    )}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    onChange={(e) =>
                                      handleChangeFilter(
                                        "desc_produto",
                                        "right",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Stack>
                              </Stack>
                            </Card>
                            {customList(rightFiltered)}
                          </Stack>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "10px",
                        }}
                      >
                        <Stack direction={"row"} gap={2} paddingRight={7}>
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<Cancel />}
                            onClick={() => navigate(-1)}
                          >
                            Cancelar
                          </Button>
                          <Button
                            variant="outlined"
                            color="success"
                            type="submit"
                            startIcon={<Check />}
                            onClick={(e) => {
                              saveAll();
                            }}
                          >
                            Confirmar
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                  ) : (
                    <FormProgress />
                  )}
                </Box>
              </Body>
            </Section>
          </>
        )}
      </RootBox>
    </Root>
  );
}
