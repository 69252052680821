// Imports de bibliotecas e Dependências
import { useNavigate } from "react-router-dom";

// Import de componentes
import { ExitToApp, Menu } from "@mui/icons-material";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { logout } from "../api/auth";
import { useGlobalState } from "../hooks/useGlobalState";
import { routes } from "../routes";
import QuestionDialog from "./QuestionDialog";

export default function Sidebar() {
  const [i, changeIndex] = useGlobalState("activeIndex");
  const [open, changeOpen] = useGlobalState("drawerOpen");
  const [indexSub, setIndexSub] = useState();
  const [navItens, setNavItens] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    var id = document.cookie;
    document.cookie = `${id}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"`;
    logout();
    navigate("/");
  };
  const role = parseInt(localStorage.getItem("role"));

 useEffect(() => {
    const sidebarNavItems = routes
      .filter((route) => route.sideBar)
      .filter((route) => route.roles.includes(role))
      .map((route) => {
        return {
          id: route.id,
          display: route.name,
          icon: route.icon,
          to: route.path,
          section: route.path,
        };
      })
      .sort(function (a, b) {
        return ("" + a.display).localeCompare(b.display);
      });
    setNavItens(sidebarNavItems);
 }, [role]);
  

  var drawerWidth = open ? "14vw" : "3vw";

  return (
    <Drawer
      variant="persistent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        overflowX: "hidden",
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          zIndex: 1,
          overflowX: "hidden",
          boxSizing: "border-box",
        },
      }}
      anchor="left"
      open={true}
    >
      <Box>
        <Stack
          p={open ? 2 : 1}
          gap={1}
          direction={"row"}
          sx={{ alignItems: "center" }}
        >
          <Typography sx={{ ...(!open && { display: "none" }) }} variant={"h5"}>
            Admin Digital
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => changeOpen(!open)}
            sx={{ ".css-i4bv87-MuiSvgIcon-root": { width: "1vw" } }}
          >
            <Menu />
          </IconButton>
        </Stack>

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {navItens.map((item, index) => (
            <Tooltip
              title={open ? "" : item.display}
              placement={"right"}
              key={index}
              arrow
            >
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  if (Object.hasOwn(item, "items")) {
                    setIndexSub(indexSub === item.id ? 0 : item.id);
                  } else {
                    changeIndex(index);
                    navigate(item.to);
                  }
                }}
                sx={{
                  ".css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root": {
                    paddingLeft: "1vw",
                  },
                }}
                // sx={{ height: '5vh' }}
              >
                <ListItemButton selected={index === i}>
                  <ListItemIcon
                    sx={{ ".css-i4bv87-MuiSvgIcon-root": { width: "1vw" } }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.display}
                    sx={{ ...(!open && { display: "none" }) }}
                  />
                  {/* {Object.hasOwn(item, "items") ? (
                      indexSub === item.id ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      <></>
                    )} */}
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
          <Divider />
          <QuestionDialog
            title={"Deseja sair?"}
            description={"Tem certeza que deseja sair?"}
            onConfirm={handleLogout}
          >
            <Tooltip title={open ? "" : "Sair"} placement={"right"} arrow>
              <ListItem
                key={"logout"}
                disablePadding
                onClick={() => handleLogout}
                sx={{
                  ".css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root": {
                    paddingLeft: "1vw",
                  },
                }}
              >
                <ListItemButton>
                  <ListItemIcon
                    sx={{ ".css-i4bv87-MuiSvgIcon-root": { width: "1vw" } }}
                  >
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Sair"}
                    sx={{ ...(!open && { display: "none" }) }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </QuestionDialog>
        </List>
      </Box>
    </Drawer>
  );
}
