import axios from "axios";
import { getToken } from "./auth";

/**
 * @function api
 * 
 * @brief Cria uma instância do AXIOS, gerando conexão com a API. Além disso, intercepta todo request feito, configurando os headers do mesmo para incluir o token.
*/
const baseUrl = process.env.REACT_APP_API_BASE_URL
const api = axios.create({
  baseURL: baseUrl
});

// Interceptação dos requests
api.interceptors.request.use(async config => {
  const token = getToken();
  
  // Setando headers para incluir JWT
  if(token){
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;